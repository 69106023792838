import React, { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import { useFormContext } from "./FormContext";

const BookAppointment = () => {
  const { formData } = useFormContext();
  const [form, setForm] = useState({ name: "", email: "" });

  useEffect(() => {
    if (formData) {
      setForm(formData);
    }
  }, [formData]);

  // Construct the Calendly URL with pre-filled parameters
  const calendlyUrl = new URL(
    "https://calendly.com/thenutritioncure-info/one-time-diet-plan"
  );
  if (form.name) {
    calendlyUrl.searchParams.append("name", form.name);
  }
  if (form.email) {
    calendlyUrl.searchParams.append("email", form.email);
  }

  return (
    <div>
      <InlineWidget
        url={calendlyUrl.toString()}
        styles={{
          height: "800px",
        }}
      />
      <style>
        {`
          #full-name-input { display: none; }
          #email-input { display: none; }
        `}
      </style>
    </div>
  );
};

export default BookAppointment;
