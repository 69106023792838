import React from "react";
import { MdPhone, MdLocationPin, MdEmail } from "react-icons/md";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const products = [
    { name: "Home", href: "/" },
    { name: "About Us", href: "/about-us" },
    { name: "Services", href: "/services" },
    { name: "Consultation", href: "/book-appointment" },
  ];

  const usefulLinks = [
    { name: "Blog Posts", href: "/blogs" },
    { name: "Media", href: "/media" },
    { name: "Contact Us", href: "/contact-us" },
    // { name: "FAQ", href: "/faq" },
  ];

  const contactDetails = [
    { icon: <MdLocationPin size={24} />, text: "Baluwatar, Nepal" },
    {
      icon: <MdEmail size={24} />,
      text: (
        <a href="mailto:info@thenutritioncure.com" className="text-reset">
          info@thenutritioncure.com
        </a>
      ),
    },
    {
      icon: <MdPhone size={24} />,
      text: (
        <a href="tel:+9779860848881" className="text-reset">
          +977-9860848881
        </a>
      ),
    },
  ];

  const socialMediaLinks = [
    {
      href: "https://www.facebook.com/thenutritioncure",
      icon: <FaFacebook size={24} />,
    },
    {
      href: "https://www.instagram.com/thenutritioncurenepal/",
      icon: <FaInstagram size={24} />,
    },
  ];

  return (
    <footer className="text-center text-lg-start primary-color text-white pt-4">
      <div className="px-5 text-center text-md-start">
        <div className="row mt-3">
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <Link to="/" className=" footerlogo ">
              <img
                src="/img/assets/footerlogo.png"
                alt=""
                className="p-2 rounded-pill mb-2 bg-white"
              />
            </Link>
            <h6 className="text-uppercase fw-bold mb-1">Nutrition Cure</h6>
            <p>Fuel Your Life with Balanced Nutrition.</p>
            <div className="d-flex gap-3 pb-4 justify-content-center justify-content-md-start">
              {socialMediaLinks.map((icon, index) => (
                <a
                  href={icon.href}
                  key={index}
                  className="text-white"
                  target="_blank"
                >
                  {icon.icon}
                </a>
              ))}
            </div>
          </div>

          <ul className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <h6 className="text-uppercase fw-bold mb-4">Quick Links</h6>
            {products.map((product, index) => (
              <li key={index} className="mb-2">
                <Link to={product.href} className="text-reset">
                  {product.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h6 className="text-uppercase fw-bold mb-4">Resources</h6>
            {usefulLinks.map((link, index) => (
              <li key={index} className="mb-2">
                <Link to={link.href} className="text-reset">
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 text-decoration-none">
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            {contactDetails.map((contact, index) => (
              <li className="mb-2" key={index}>
                {contact.icon} {contact.text}
              </li>
            ))}
          </ul>

          <div className="border-top border-light pt-4 text-center">
            <p>© 2024 Nutrition Cure. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
