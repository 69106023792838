import React, { useState } from "react";
import PhoneNumberInput from "./PhoneNumberInput";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import AlertMessage from "./AlertMessage";
import FormSubmissionHandler from "../services/FormSubmissionHandler";
import SendThankYouEmail from "../services/SendThankYouEmail";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: "", message: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!name) newErrors.name = "This field is required";
    if (!email) newErrors.email = "This field is required";
    if (!phone) newErrors.phone = "This field is required";
    if (!message) newErrors.message = "This field is required";

    setErrors(newErrors);

    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0) {
      // Handle form submission logic here
      console.log("Form submitted:", { name, email, phone, message });

      try {
        const res = await FormSubmissionHandler({
          fullName: name,
          email: email,
          phone: phone,
          message: message,
          pageDetails: "contact",
        });

        if (res.errors) {
          setAlert({ type: "danger", message: res.errors });
        } else {
          setAlert({ type: "success", message: "Form submitted successfully" });
          // Reset form fields
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");

          // Send thank-you email
          await SendThankYouEmail({ email, name, pageType: "contactPage" });

          // Clear alert after 3 seconds (optional)
          setTimeout(() => {
            setAlert({ type: "", message: "" });
          }, 3000);
        }
      } catch (error) {
        console.log("Error submitting form:", error);
      }
    }
  };

  const handleCloseAlert = () => {
    setAlert({ type: "", message: "" }); // Clear the alert message
  };

  return (
    <div className="container my-5 " style={{ minHeight: "60vh" }}>
      <div className="row justify-content-center mx-auto">
        <div className="col-12 col-md-5 me-4">
          <div className="card p-3 d-flex flex-col border-0">
            <h1 className="card-title">Get in touch</h1>
            <p className="card-text">
              The <span className=" fw-medium">Nutrition Cure</span> is an
              online nutrition & diet consultation clinic, which works with a
              vision to assist everyone to unlock unsalutary lifestyle to
              reinstate the natural wellbeing of the body. Everyone’s body
              differs in terms of calorie requirement, nutrient utilization,
              response to food, digestion, metabolism.
            </p>

            <div className="contact-info">
              <p className="d-flex align-items-center mb-2">
                <AiOutlineMail className="primary-text-color fs-5 me-2" />
                <span>Email: info@thenutritioncure.com</span>
              </p>
              <p className="d-flex align-items-center">
                <AiOutlinePhone className="primary-text-color fs-5 me-2" />
                <span>Phone: +977 9860848881</span>
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-5 ms-md-4 mt-md-5 mt-md-0">
          {alert.message && (
            <AlertMessage // render only when `alert.message` isn't empty
              type={alert.type}
              message={alert.message}
              onClose={handleCloseAlert}
            />
          )}

          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputName"
                placeholder="Your Name"
                // required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="floatingInputName">Your Name</label>
              {errors.name && (
                <small className="text-danger">{errors.name}</small>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInputEmail"
                placeholder="Your Email Address"
                // required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="floatingInputEmail">Your Email Address</label>
              {errors.email && (
                <small className="text-danger">{errors.email}</small>
              )}
            </div>

            <PhoneNumberInput phone={phone} setPhone={setPhone} />
            {errors.phone && (
              <small className="text-danger">{errors.phone}</small>
            )}

            <div className="form-floating mt-3">
              <textarea
                className="form-control"
                id="floatingTextareaMessage"
                placeholder="Your Message"
                style={{ height: "100px" }}
                // required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <label htmlFor="floatingTextareaMessage">Your Message</label>
              {errors.message && (
                <small className="text-danger">{errors.message}</small>
              )}
            </div>

            <button type="submit" className="primary-button mt-3">
              Send Email
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
