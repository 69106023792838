import axiosInstance from "./axiosInstance";

const SendThankYouEmail = async ({ email, name, pageType }) => {
  const token = process.env.REACT_APP_FORM_SUBMISSION_BEARER_TOKEN;

  try {
    // Define email templates based on the page type with HTML formatting
    const emailTemplates = {
      contactPage: {
        subject: "Thank You for Contacting Us!",
        body: `

       <html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        background-color: #f8f9fa;
        color: #212529;
        margin: 0;
        padding: 0;
      }
      .container {
        width: 90%;
        max-width: 600px;
        margin: 20px auto;
        background-color: #ffffff;
        border: 1px solid #dee2e6;
        border-radius: 8px;
        /* padding: 20px; */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }


      .content {
        padding: 20px;
      }
      .header {
        background-color: #198942;
        color: #ffffff;
        padding: 10px;
        border-radius: 8px 8px 0 0;
        text-align: center;
        margin-bottom: 20px;
      }
      .footer {
        margin-top: 20px;
        text-align: center;
      }
      .footer a {
        color: #198942;
        text-decoration: none;
      }
      .button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff !important;
        background-color: #198942;
        border-radius: 4px;
        text-decoration: none;
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 4px;
        justify-content: center;
      }
      ul li {
        margin-bottom: 10px;
      }
      ul li a {
        color: #007bff;
        text-decoration: none;
      }
      p {
        margin: 0 0 10px;
      }
      .social-icon {
        width: 30px;
        height: 30px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <h2>The Nutrition Cure</h2>
      </div>
      <div class="content">
        <p>Dear ${name},</p>
        <p>
          Thank you for reaching out to us! We have received your message and
          will get back to you within 24 hours.
        </p>
        <p>
          You can also follow us on our social media platforms to stay updated:

        </p>
        <ul>
          <li>
            <a href="https://www.facebook.com/thenutritioncure">
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/thenutritioncurenepal/">
              Instagram
            </a>
          </li>
        </ul>
        <p class="footer">
          <a href="https://thenutritioncure.com/" class="button">
            Visit Our Website
          </a>
        </p>
        <p>Best regards,</p>
        <p>The Nutrition Cure Team</p>
      </div>
    </div>
  </body>
</html>   `,
      },
      bookingPage: {
        subject: "Your Appointment is Confirmed!",
        body: `
         <html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        background-color: #f8f9fa;
        color: #212529;
        margin: 0;
        padding: 0;
      }
      .container {
        width: 90%;
        max-width: 600px;
        margin: 20px auto;
        background-color: #ffffff;
        border: 1px solid #dee2e6;
        border-radius: 8px;
        /* padding: 20px; */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }


      .content {
        padding: 20px;
      }
      .header {
        background-color: #198942;
        color: #ffffff;
        padding: 10px;
        border-radius: 8px 8px 0 0;
        text-align: center;
        margin-bottom: 20px;
      }
      .footer {
        margin-top: 20px;
        text-align: center;
      }
      .footer a {
        color: #198942;
        text-decoration: none;
      }
      .button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff !important;
        background-color: #198942;
        border-radius: 4px;
        text-decoration: none;
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 4px;
        justify-content: center;
      }
      ul li {
        margin-bottom: 10px;
      }
      ul li a {
        color: #007bff;
        text-decoration: none;
      }
      p {
        margin: 0 0 10px;
      }
      .social-icon {
        width: 30px;
        height: 30px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="header">
        <h2>The Nutrition Cure</h2>
      </div>
      <div class="content">
        <p>Dear ${name},</p>
        <p>
          Thank you for reaching out to us! We have received your message and
          will get back to you within 24 hours.
        </p>
        <p>
          You can also follow us on our social media platforms to stay updated:
        </p>
        <ul>
          <li>
            <a href="https://www.facebook.com/thenutritioncure">
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/thenutritioncurenepal/">
              Instagram
            </a>
          </li>
        </ul>
        <p class="footer">
          <a href="https://thenutritioncure.com/" class="button">
            Visit Our Website
          </a>
        </p>
        <p>Best regards,</p>
        <p>The Nutrition Cure Team</p>
      </div>
    </div>
  </body>
</html>

        `,
      },
    };

    // Select the correct email template based on the page type
    const selectedTemplate = emailTemplates[pageType];

    // Create the payload for the email API
    const payload = {
      ToEmailAddress: email,
      Subject: selectedTemplate.subject,
      Body: selectedTemplate.body,
      IsHtml: true, // Make sure to specify that the body is HTML
    };

    // Send the email using the API
    const response = await axiosInstance.post("/api/sendEmail", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Ensure this header is set for JSON payload
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error sending email:",
      error.response?.data || error.message
    );
  }
};

export default SendThankYouEmail;
