import React from "react";
import { useLocation } from "react-router-dom";

const Hero = () => {
  const location = useLocation();
  const isHomepage = location.pathname == "/";
  return (
    <section className={`${isHomepage ? "py-5" : " "}`}>
      <div className="container row mx-auto mt-5">
        <div className="col">
          <div className="row">
            <p className=" pe-md-5 text-justify">
              Welcome to TheNutritionCure, where your health and well-being are
              our top priority. Based in the heart of Kathmandu, Nepal, we are a
              dedicated team of experienced nutritionists passionate about
              empowering individuals to live healthier lives through
              personalized nutrition and wellness solutions. Our mission is to
              transform the way people approach their health by providing them
              with the knowledge and tools they need to make informed choices
              about their diet and lifestyle. Our expertise spans across various
              aspects of nutrition, including healthy diet planning, fitness,
              and wellness, all of which are tailored to meet the unique needs
              of each individual. Whether you're a toddler just starting on
              solid foods, an adult looking to optimize your diet, a pregnant
              woman seeking the best nutrition for you and your baby, or someone
              managing a chronic illness, we are here to guide you on your
              journey to better health.
            </p>
          </div>
        </div>
        <div className="col-md">
          <img
            src="/img/heroimg.jpg"
            alt="Diet food"
            className="w-100 rounded"
          />
        </div>
      </div>
      <div className="container ">
        <p className="text-justify pe-md-3 px-3 mt-4">
          At TheNutritionCure, we believe that proper nutrition is the
          foundation of a healthy life. We offer a comprehensive range of
          services, including one-on-one consultations, personalized diet plans,
          and ongoing follow-ups to ensure you stay on track with your health
          goals. Our team works closely with you to create a sustainable and
          enjoyable approach to eating, whether you are looking to improve your
          overall well-being, manage a specific health condition, or simply be
          more conscious about your diet. We are proud to serve individuals from
          all walks of life and are committed to helping you achieve the results
          you desire.
        </p>
        <p className="text-justify px-3 pe-md-3">
          In addition to our individual services, TheNutritionCure is also
          deeply involved in the community. We organize free health camps,
          provide donations to those in need, and collaborate with corporate
          houses to manage their employees' diet plans. We also support various
          events and pageants, offering our expertise to help participants
          manage their nutrition journey effectively. Our mission is to make a
          positive impact on the lives of as many people as possible, promoting
          a culture of health and wellness in our society. Join us at
          TheNutritionCure, and let us help you take control of your health and
          nutrition today.
        </p>
      </div>
    </section>
  );
};

export default Hero;
