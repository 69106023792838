import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <Carousel data-bs-theme="light" className="main-slider" indicators={false}>
      <Carousel.Item className="carousel-image-container">
        <img
          className="d-block w-100"
          src="/img/banner/pregnancybanner.png"
          alt="First slide"
        />
        <div className="overlay"></div>
        <Carousel.Caption className="carousel-caption">
          <h2 className="text-uppercase fs-1 fw-bold">Mama’s Nutrition</h2>
          <p className="description">
            Tailored Diet for a Happy, Healthy Pregnancy
          </p>
          <div className="mt-4">
            <Link
              to="book-appointment"
              className="fw-medium rounded-pill text-white border bg-gray shadow carousel-button"
            >
              Book Now
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel-image-container">
        <img
          className="d-block w-100"
          src="/img/banner/demobannergym.png"
          alt="Second slide"
        />
        <div className="overlay"></div>
        <Carousel.Caption className="carousel-caption">
          <h2 className="text-uppercase fs-1 fw-bold">Wellness Essentials</h2>
          <p className="description">Expert Nutrition for a Vibrant Body.</p>
          <div className="mt-4">
            <Link
              to="book-appointment"
              className="fw-medium rounded-pill text-white border bg-gray shadow carousel-button"
            >
              Book Now
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel-image-container">
        <img
          className="d-block w-100 "
          src="/img/banner/banner3.png"
          alt="Third slide"
        />
        <div className="overlay"></div>
        <Carousel.Caption className="carousel-caption">
          <h2 className="text-uppercase fs-1 fw-bold">Smart Diet Plans</h2>
          <p className="description">
            Personalized Diet Plans for Better Health.
          </p>
          <div className="mt-4">
            <Link
              to="book-appointment"
              className="fw-medium rounded-pill text-white border bg-gray shadow carousel-button"
            >
              Book Now
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
