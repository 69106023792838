import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import NavComponent from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import ErrorPage from "./components/ErrorPage";
import ContactUs from "./components/ContactUs";
import BlogList from "./components/BlogList";
import BlogPost from "./components/BlogPost";
import ScrollToTop from "./components/ScrollToTop";
import Media from "./components/Media";
import AboutUs from "./components/AboutUs";
import TawkMessenger from "./components/TawkMessenger";
import Services from "./components/Services";
import AppointmentForm from "./components/AppointmentForm";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <NavComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/media" element={<Media />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/book-appointment" element={<AppointmentForm />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />

      {/* TawkMessenger component */}
      <TawkMessenger />
    </Router>
  );
}

export default App;
