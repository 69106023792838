import React from "react";

const AlertMessage = ({ type, message, onClose }) => {
  if (!message) return null; // Don't render if there's no message

  return (
    <>
      <div
        className={`alert alert-${type} alert-dismissible fade show`}
        role="alert"
      >
        {message}
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
        ></button>
      </div>
    </>
  );
};

export default AlertMessage;
