import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";

const NavComponent = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleLinkClick = (path) => {
    setShow(false);
    if (location.pathname === path) {
      document.location.reload();
    }
  };

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className="bg-white shadow py-4 relative px-md-5 px-2 z-11"
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/" onClick={() => handleLinkClick("/")}>
          <img src="/img/assets/image.png" alt="" className="logoimg" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-lg"
          onClick={() => setShow(!show)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
          show={show}
          onHide={() => setShow(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
              Diet Portal
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 gap-lg-2 nav-text">
              <Nav.Link
                as={Link}
                to="/"
                className="nav-text"
                onClick={() => handleLinkClick("/")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about-us"
                className="nav-text"
                onClick={() => handleLinkClick("/about-us")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/services"
                className="nav-text"
                onClick={() => handleLinkClick("/services")}
              >
                Services
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/blogs"
                className="nav-text"
                onClick={() => handleLinkClick("/blogs")}
              >
                Healthy Diet
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/media"
                className="nav-text"
                onClick={() => handleLinkClick("/media")}
              >
                Media
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className="nav-text"
                onClick={() => handleLinkClick("/contact-us")}
              >
                Contact Us
              </Nav.Link>
              <div className="nav-link-margin">
                <Link
                  to="/book-appointment"
                  className="primary-button"
                  onClick={() => handleLinkClick("/book-appointment")}
                >
                  Book Appointment
                </Link>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
