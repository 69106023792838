import React from "react";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

const pricingPlans = [
  {
    title: "One Time Plan",
    description: "Relevant for multiple users, extended & premium support.",
    price: "Rs. 1000",
    features: [
      "General confusion and general information consultation",
      "1 Time Consultation",
      "1 Hour Maximum",
    ],
  },
  {
    title: "Single Consultation",
    description:
      "Recommended for those seeking short term change in lifestyle.",
    price: "Rs. 3,800",
    features: [
      "1st Consultation",
      "Trial Chart",
      "2nd Consultation",
      "Final Chart",
    ],
  },

  {
    title: "Multiple Consultation",
    description: "Relevant for multiple users, extended & premium support.",
    price: "Rs. 12,000",
    features: [
      "6 months package",
      "For critical cases",
      "Long term cases",
      "Regular monitoring of patient requests",
      "1st consultation",
      "Tiral chart",
      "2nd consultation",
      "Final Chart",
      "Regular update",
      "Every month the chart changes based on need",
    ],
  },
];

const Pricing = () => {
  return (
    <section className="bg-light text-dark py-5">
      <div className="container">
        <div className="text-center mb-8 mb-lg-12">
          <h2 className="fw-bold text-center ">
            Our
            <span className="primary-text-color"> Package</span>
          </h2>
          <div className="row justify-content-center">
            <p className="text-muted col-md-6 mb-4 mb-md-5">
              Our plans offer various diet and consultation options tailored to
              your needs. Select a package that facilitates effective goal
              achievement and long-term success.
            </p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {pricingPlans.map((plan, index) => (
            <div className="col" key={index}>
              <div className="card p-4 border-light shadow-sm">
                <div className="card-body text-center">
                  <h3 className="fs-4 primary-text-color mb-2">{plan.title}</h3>
                  <p className="card-text mb-3 mb-md-4 text-muted">
                    {plan.description}
                  </p>
                  <div className="d-flex justify-content-center mb-2">
                    <span className="display-6 fw-bold">{plan.price}</span>
                    {/* <span className="text-muted ms-1 d-flex align-items-end pb-1">
                      /month
                    </span> */}
                  </div>
                  <ul className="list-unstyled mb-8">
                    {plan.features.map((feature, featureIndex) => (
                      <li className=" text-start mb-3" key={featureIndex}>
                        <FaCheck className="primary-text-color me-1" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/book-appointment"
                    className="primary-button px-4 w-100 mt-3"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
