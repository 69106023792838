import React from "react";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { RiVerifiedBadgeLine } from "react-icons/ri";

const FeatureCard = () => {
  return (
    <section className="py-5">
      <h2 className="pb-3 pb-md-5 fw-bold text-center ">
        Our
        <span className="primary-text-color"> Features</span>
      </h2>
      <div className="container mx-auto max-w-5xl d-flex flex-wrap gap-md-4 justify-content-center">
        <div className="col-md-3 d-flex flex-column align-items-center text-center mb-4">
          <div className="icon-container mb-2">
            <HiOutlineLockClosed size={42} />
          </div>
          <h3 className="fw-bold fs-2">Safe</h3>
          <p>Expertly designed diet plans for your safety and health.</p>
        </div>

        <div className="col-md-3 d-flex flex-column align-items-center text-center mb-4">
          <div className="icon-container mb-2">
            <AiOutlineThunderbolt size={42} />
          </div>
          <h3 className="fw-bold fs-2">Efficient</h3>
          <p>Streamlined nutrition planning to fit your busy life.</p>
        </div>

        <div className="col-md-3 d-flex flex-column align-items-center text-center mb-4">
          <div className="icon-container mb-2">
            <RiVerifiedBadgeLine size={42} />
          </div>
          <h3 className="fw-bold fs-2">Proven</h3>
          <p>Trusted results with a decade of successful dietary solutions.</p>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard;
