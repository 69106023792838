import React from "react";

const Services = () => {
  const steps = [
    {
      id: 1,
      img: "/img/services/toddler.png",
      title: "Toddler",
      description:
        "Diet guidance from the Nutrition Cure supports toddlers. It helps to build lasting healthy habits.",
    },
    {
      id: 2,
      img: "/img/services/child.png",
      title: "Children",
      description:
        "The Nutrition Cure helps children manage stress and focus better. It supports their growth and emotional health.",
    },
    {
      id: 3,
      img: "/img/services/adult.png",
      title: "Adult",
      description:
        "Nutrition tips from the Nutrition Cure aids adult health through diet. It boosts energy and prevents diseases.",
    },
    {
      id: 4,
      img: "/img/services/elder.png",
      title: "Elder",
      description:
        "The dietician's diet tips strengthen bones and mind in older adults. It helps prevent diseases and boosts life quality.",
    },
    {
      id: 5,
      img: "/img/services/disease.png",
      title: "Critical Cases",
      description:
        "Nutritional support for admitted patients or pre-surgery, post-surgery and ICU patients. Proper intake is vital in such condition.",
    },
    {
      id: 6,
      img: "/img/services/gym.png",
      title: "Gym and Athlete",
      description:
        "The portal’s nutrition tips boost workouts and athletic performance. They aid muscle repair and sustain energy.",
    },
    {
      id: 7,
      img: "/img/services/paegent.png",
      title: "Pageants",
      description:
        "The portal’s nutrition tips enhance energy and radiance for pageant contestants. Proper intake supports top performance.",
    },
    {
      id: 8,
      img: "/img/services/yoga.png",
      title: "Yoga and Meditation",
      description:
        "Nutrition supports energy and flexibility while improving mental clarity for yoga. The portal ensures balanced intake.",
    },
    {
      id: 9,
      img: "/img/services/employee.png",
      title: "Co-operates",
      description:
        "The portal ensures proper nutrition for alertness and efficiency at work. It enhances health and productivity.",
    },
    {
      id: 10,
      img: "/img/services/kitchen.png",
      title: "Kitchen Quality",
      description:
        "Organizations and school can hire expert dietitian from us for their regular monitoring of kitchen quality and nutritional menu modifications.",
    },
  ];

  return (
    <section className="bg-light py-5">
      <h2 className="fw-bold text-center">
        Our
        <span className="primary-text-color"> Services</span>
      </h2>
      <div className="pb-3 px-3">
        <p className="text-center fw-medium">
          Services that we offer at the Nutrition Cure
        </p>
      </div>
      <div className="row px-3 px-md-5 mx-auto">
        {steps.map((step) => (
          <div key={step.id} className="col-md-4 col-sm-6 mb-4">
            <div className="p-4 shadow rounded bg-white">
              <div className="card-body">
                <div className="services-img pb-3">
                  <img src={step.img} alt={step.title} />
                </div>
                <h5 className="card-title text-center pb-2">{step.title}</h5>
                <p className="card-text">{step.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
