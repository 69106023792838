import React from "react";
import { useParams } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";

const blogPosts = [
  {
    id: 1,
    title: "Healthy Heart Diet",
    date: "August 4, 2024",
    img: "/img/demo1.png",
    content: null,
    subheadings: [
      {
        title: "Fat",
        subtitle: null,
        content:
          "Fat plays an important role in the body, supplying vitamins and minerals, giving the body insulation and also energy. You need some fat in your diet for general good health, but too much of the wrong type of fat can cause weight gain and be a risk factor for heart disease by increasing your cholesterol. ",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content:
          "Bad fats to avoid are called saturated fats these are usually found in animal products and processed foods. These include:",
        img: null,
        lists: [
          "Cakes and Biscuits",
          "Full Fat Dairy",
          "Takeaway Foods",
          "Fatty cuts of Meat",
          "Butter and Ghee and Coconut Oil",
          "Salami and Sausages",
        ],
      },
      {
        title: null,
        subtitle: null,
        content:
          "Good fats to eat called monounsaturated and polyunsaturated fat, these are protective and good for your heart health. These include:",
        img: null,
        lists: [
          "Unsalted nuts like cashews, peanuts, walnuts, pistachios, almonds",
          "Seeds like sunflower seeds",
          "Oily fish like salmon or tuna (tinned fish is good too!) – eat fish twice per week for your heart",
          "Avocardo",
          "Oils like olive oil, canola oil, sunflower oil, rice brain oil",
        ],
      },
      {
        title: "Protein",
        subtitle: null,
        content:
          "Your body uses protein to build and repair tissues, make enzymes, hormones and other chemicals. Protein is the building blocks of bones, muscles, cartilage, skin and blood. Protein plays a big role in weight loss as it can help keep you full for longer and stop hunger cravings. You should try to add some protein to each of your meals and snacks.",
        img: null,
        lists: [],
      },
      {
        title: null,
        content: "Foods high in protein include:",
        img: null,
        lists: [
          "Chicken",
          "Fish",
          "Pork and other meats",
          "Eggs",
          "Panner and Cheese",
          "Nuts and Seeds",
          "Tofu",
          "Lentils, chickpeas, kidney beans",
        ],
      },
      {
        title: "Foods To Avoid",
        subtitle: null,
        content:
          "When trying to loose weight its important to avoid foods which are high in calories. This is usually junk food such as chips, lollies, biscuits, cakes, pastries, packets of instant noodles, burgers, pizza, fried foods, icecream, sausages and salami.",
        img: null,
        lists: [],
      },

      {
        title: "Think About Your Drinks",
        subtitle: null,
        content:
          "Don’t forget that some drinks also have high calories, so its best to avoid them. This includes, coke, sprite, fanta, alcohol, milkshakes, coffees with added icecream and even juice. Overall the best drink to have is water and low fat milk for calcium.",
        img: null,
        lists: [],
      },

      {
        title: "Healthy Heart Diet",
        subtitle: "High In",
        content: null,
        img: null,
        lists: [
          "Antioxidants (from fruits and vegetables)",
          "Dietary fibre (from fruits and Vegetables and wholegrain products)",
        ],
      },

      {
        title: null,
        subtitle: "Moderate in good fats",
        content: null,
        img: null,
        lists: [],
      },

      {
        title: null,
        subtitle: "Low In",
        content: null,
        img: null,
        lists: [
          "Saturated fat",
          "Added sugars",
          "Salt",
          "Processed foods",
          "Alcohol (no more than 2 drinks at a time!)",
        ],
      },

      {
        title: "Tips to Decrease Salt Intake",
        subtitle: null,
        content:
          "Salt raises blood pressure which can cause heart failure and heart attack. Tips to avoid salt include:",
        img: null,
        lists: [
          "Avoid processed and takeaway foods e.g. instant noodles or chowmein from outside",
          "Avoid adding salt to cooking",
          "Avoid salted nuts and chips",
          "Avoid processed meat like sausage",
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Low FODMAP Diet",
    date: "August 5, 2024",
    img: "/img/blog/lowfodmap.png",
    content: null,
    subheadings: [
      {
        title: "What is Low FODMAP Diet?",
        subtitle: null,
        content:
          "The Low FODMAP diet involves many dietary changes that are best reviewed in consultation with an accredited dietitian. However a brief overview and sample meal plan is shown below.",
        img: null,
        lists: [],
      },
      {
        title: "What are FODMAPS?",
        subtitle: null,
        content:
          "FODMAPs are a large group of dietary sugars found in many common foods such as specific dairy products, wheat and other grains, and fruits and vegetables.",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content:
          "FODMAPs are found in many foods we commonly eat and is acronym for: ",
        img: null,
        lists: [
          "Fermentable",
          "Oligosaccharides",
          "Disaccharides",
          "Monsachharides",
          "Ployols",
        ],
      },
      {
        title: "How to FODMAPs affect people with IBS",
        subtitle: null,
        content:
          "FODMAPs are small in size and will therefore have an osmotic effect (draw fluid) in the gut that results in increased delivery of water through the bowel. FODMAPs are also poorly absorbed in the small intestine. They continue along the digestive tract to the large intestine where they are fermented by bacteria in the large intestine, which produces gas. The gas production can lead to wind (flatulence), bloating, discomfort and abdominal pain. In addition, the large intestinal gas and increased water delivery can alter ‘motility’ or movement, which may contribute to diarrhoea and/or constipation.",
        img: null,
        lists: [],
      },
      {
        title: "Do FODMAPS affect everybody",
        subtitle: null,
        content:
          "While FODMAPs are poorly absorbed in all people, those with specific gut disorders such as IBS are thought to experience the uncomfortable symptoms due to the gut being unusually sensitive. Distension or ‘stretch’ in the large intestine from gas or water can trigger symptoms.",
        img: null,
        lists: [],
      },
      {
        title: "Diganosis",
        subtitle: null,
        content:
          "There is no diagnostic test for IBS. Diagnosis is made on symptoms. It is therefore important to work with medical professionals to exclude other serious GI conditions (e.g. inflammatory bowel disease, coeliac disease and bowel cancer) and also some gynaecological conditions.",
        img: null,
        lists: [],
      },
      {
        title: "Testing for Poor Absorption",
        subtitle: null,
        content:
          "Hydrogen/methane breath-testing can be used to assess if a person absorbs fructose, lactose and sorbitol effectively. It may also be helpful in tailoring the Low FODMAP Diet. Breath tests are not essential. A FODMAP restricted diet can be implemented by your dietitian, who can then take you through challenges to determine which of the FODMAP carbohydrates you need to avoid for symptom relief. They will also help you determine your degree of absorption, allowing you to consume small amounts of high FODMAP foods without symptoms..",
        img: null,
        lists: [],
      },
      {
        title: "How do I follow the Low FODMAP Diet?",
        subtitle: null,
        content:
          "It is essential to develop a strategy and plan ahead. Work with your dietitian to develop easy and tasty meal plans. Ask for a low FODMAP shopping guide. Maintaining a low FODMAP pantry is key to sustaining a Low FODMAP Diet. When reducing FODMAPs in the diet it is still important to balance good nutrition with symptom control and eat from the five food groups",
        img: null,
        lists: [
          "Vegetables => 5-7 servings/day",
          "Bread, cereals, rice, pasta, noodles => 4 servings/day",
          "Fruit => 4 servings/day",
          "Meat, fish, poultry => 1-2 servings/day",
          "Dairy => 2-3 servings/day",
        ],
      },
      {
        title: "Low FODMAP Food Tips",
        subtitle: null,
        content: null,
        img: null,
        lists: [
          "Choose colourful fruits low in FODMAPs such as strawberries, bananas, blueberries, grapes, rockmelon, pineapple, oranges and kiwifruit",
          "Select vegetables such as spinach, carrots, capsicum, eggplant, bok choy, tomatoes, zucchini and potatoes",
          "Purchase wheat and rye free, all-purpose flour blends that are free of soy",
          "Select low lactose dairy foods such as ripened cheeses including parmesan and swiss, and lactose-free yoghurt and lactose-free kefir milk",
        ],
      },
      {
        title: "Low FODMAP Diet sample meal plan",
        subtitle: "Breakfast",
        content: null,
        img: null,
        lists: [
          "Gluten-free or spelt toast with Vegemite",
          "Cereal (oats, porridge, cornflakes)",
          "Homemade low FODMAP muesli",
          "Poached egg and spinach",
          "Lactose free yoghurt and serve of suitable fruit",
          "Tea or coffee",
        ],
      },
      {
        title: null,
        subtitle: "Lunch",
        content: null,
        img: null,
        lists: [
          "Gluten-free or spelt sandwich with fillings",
          "Sushi or rice paper rolls with suitable fillings",
          "Frittata",
          "Homemade soup with low FODMAP vegetables",
          "Fresh salads with tuna, pine nuts, lemon juice or olive oil",
          "Gluten-free pizza with low FODMAP vegetables",
          "1/2 cup blueberries and lactose-free vanilla yogurt",
        ],
      },
      {
        title: null,
        subtitle: "Dinner",
        content: null,
        img: null,
        lists: [
          "Grilled chicken, salmon, steak with salad and potatoes",
          "Lamb shanks with mashed sweet potato, carrots and green beans",
          "Tofu stir fry with rice noodles, capsicum, carrot, bok choy, ginger, chilli, soy sauce",
          "Baked potato with skin (butter optional), hard cheese, lean bacon, chives, grated carrot",
          "Gluten free pasta with sauce (no garlic or onion)",
          "Risotto with chicken, cherry tomatoes, capsicum, zucchini (use homemade stock)",
        ],
      },
      {
        title: null,
        subtitle: "Safe snacks",
        content: null,
        img: null,
        lists: [
          "Two rice cakes spread with peanut butter",
          "Banana, kiwifruit",
          "Lacotse-free yoghurt with blueberries",
          "Walnuts",
          "Lactose free milk",
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Healthy Eating for People with Diabetes",
    date: "August 5, 2024",
    img: "/img/blog/diabities.png",
    content: null,
    subheadings: [
      {
        title: "What is Diabetes?",
        subtitle: null,
        content:
          "Diabetes is a condition where there is too much sugar (or known is glucose) in the blood stream. It is normal and healthy to have some sugar in the blood, but to be diagnosed with diabetes, you have high blood sugar. This is due to your own bodies' insulin not working or your body becoming resistant to the insulin. Insulin helps to lower the blood sugar and take the blood sugar away from your blood stream and into your body to use as fuel or energy. Diabetes is a progressive disease, which means if you don’t look after it, it will get worse over time. People get diabetes for different reasons including family history, poor diet, and lack of exercise, overweight and obesity.",
        img: null,
        lists: [],
      },
      {
        title: "What impact does food have on my diabetes?",
        subtitle: null,
        content:
          "Having a health diet and active lifestyle is important for everyone, including people with diabetes. Having a healthy diet and doing regular exercise is an important part of managing your diabetes because it will help manage the high sugar in your blood and control your body weight. Meals that are recommended for people with diabetes are the same as those without diabetes and there is no need to prepare separate meals or buy special foods.",
        img: null,
        lists: [],
      },
      {
        title: "How can a dietitian help?",
        subtitle: null,
        content:
          "Your family doctor or diabetes doctor will decide if you need to be on diabetes medicine or not and if you have to test your blood sugar at home. Your dietitian will educate you on the type, timing and amount of food you eat to help with your blood sugar control. Your dietitian will also help you to loose weight and exercise if you are overweight.",
        img: null,
        lists: [],
      },
      {
        title: "Carbohydrate Foods",
        subtitle: null,
        content:
          "Carbohydrate foods are the foods which put sugar in your blood. Carbohydrate foods are important to eat as they supply your body with energy or fuel and vitamins and minerals, however if you eat too much your blood sugar can go high. Carbohydrate foods include: ",
        img: null,
        lists: [
          "Rice and rice products",
          "Bread including roti and chapatti",
          "Potato and sweet potato",
          "Corn",
          "Fruits",
          "Milk, yoghurt and curd",
        ],
      },
      {
        title: "Carbohydrate timing",
        subtitle: null,
        content:
          "To help control the sugar in your blood, its important to spread out your carbohydrate foods during the day, rather than having one large meal per day, it’s a better idea to have 6 small meals per day and having something to eat every 2-3 hours while you are awake.",
        img: null,
        lists: [],
      },
      {
        title: "Carbohydrate quantity",
        subtitle: null,
        content:
          "It's important to eat a consistent amount of carbohydrates at every main meal and snack. Eating too much carbohydrate at once will make your sugars to go high; however eating not enough carbohydrate can make your sugars go low. Your dietitian will tell you how much the right amount of carbohydrate for your body is.",
        img: null,
        lists: [],
      },
      {
        title: "Carbohydrate type",
        subtitle: null,
        content:
          "Some carbohydrate foods cause a spike or rise to your blood sugars; however some carbohydrate foods cause your blood sugars to be steady and constant. We call the foods that make your blood sugars steady low GI or low glycemic index. This means that the sugar is slowly released into your blood. Low GI foods include basmati rice, oats, most fruits except watermelon and pineapple, sweet potato, pastas and multigrain bread.",
        img: null,
        lists: [],
      },
      {
        title: "Fat",
        subtitle: null,
        content:
          "Unfortunately having diabetes increases your risk of heart attack. So its important to make sure you aren’t consuming too much of the wrong type of fat in your diet. We need some fat in our diet for vitamins, insulation and hormone function. However too much fat can lead to high cholesterol, high blood lipids and heart disease. Avoid foods high in bad fat or saturated fat. This includes:",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content:
          " Avoid foods high in bad fat or saturated fat. This includes:",
        img: null,
        lists: [
          "Cakes, biscuits, pastries, donuts",
          "Full fat dairy including cheese",
          "Takeaway foods such as KFC, pizza, burgers and Nepali fast foods like momo and chowmein",
          "Fatty cuts of meat such as pork, buff and mutton ",
          "Chicken skin ",
          "Butter, ghee and coconut oil",
          "Salami and sausages ",
        ],
      },
      {
        title: null,
        subtitle: null,
        content:
          "Include foods high in good fats or monounsaturated and polyunsaturated fat. This includes: ",
        img: null,
        lists: [
          "Unsalted nuts like cashews, peanuts, walnuts, pistachios and almonds ",
          "Seeds like sunflower seeds",
          "Oily fish like salmon or tuna (tinned is good too)- eat fish at least twice per week for your heart health",
          "Avocado",
          "Oils like olive oil, sunflower oil, rice bran oil and canola oil ",
        ],
      },
      {
        title: "Protein",
        subtitle: null,
        content:
          "Protein is used for building blocks of the body and makes bones, muscles and cartilage. Protein foods DO NOT raise your blood sugar levels and protein can play a big role in weight loss by helping you to feel full. Protein foods contain other important vitamins and minerals like iron and vitamin B12 required for a healthy body. Foods like in protein include: ",
        img: null,
        lists: [
          "Chicken- remember to remove the skin",
          "Fish",
          "Pork and other meats",
          "Eggs",
          "Panner and cheese",
          "Nuts and seeds",
          "Tofu",
          "Lentils, chickpeas, kidney beans",
        ],
      },
      {
        title: "Free Foods",
        subtitle: null,
        content:
          "Some foods have are so low in carbohydrate they do not put any sugar in your blood. These are foods you can eat more of. This includes: ",
        img: null,
        lists: [
          "All vegetables (NOT potato, sweet potato and corn) ",
          "Onion, garlic, chilli",
          "Spices and masalas",
          "Fresh berries like strawberries",
          "Fresh guava",
          "Black tea or coffee",
          "Herbal tea",
          "Artificial sweeteners like Stevia",
        ],
      },
      {
        title: "Foods High in Sugar/Carbohydrate",
        subtitle: null,
        content:
          "Some foods are high in sugar and therefore when you eat them, they will put sugar in your blood. This is not good for diabetes control. This includes: ",
        img: null,
        lists: [
          "Cakes, biscuits, pastries",
          "Fruit juice",
          "Coke, sprite and fanta",
          "Ice-cream",
          "Indian sweets and desserts",
          "Potato and potato chips",
        ],
      },
      {
        title: "Low Blood Sugars",
        subtitle: null,
        content:
          "If you take certain tablets or insulin, you have a risk of your blood sugars going low. Low blood sugars are a medical emergency. Your sugars could have gone low because of a missed or delayed meal, eating not enough carbohydrate or medication imbalances. Signs of low blood sugars:",
        img: null,
        lists: [
          "Sweaty",
          "Shakey",
          "Headache",
          "Hungry",
          "Confused",
          "Food change or angry",
          "Feel weak or strange ",
        ],
      },
      {
        title: null,
        subtitle: null,
        content: "If you suspect your sugars are low follow these steps:",
        img: null,
        lists: [
          "Tell Someone",
          "Check your blood sugars- if they are less than 90 then you are having low blood sugar",
          "Eat/drink a quick acting sugar like ½ bottle of coke/sprite/fanta or 6 jelly beans/lollies, 1 large spoon of honey ",
          "Wait 10-15 minutes to see how you feel, recheck your blood sugar ",
          "If they have returned to above 90 then eat a more complex carbohydrate like 1 fruit, 1 bread, 1 cup of milk ",
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Nutritional Management During Pregnancy",
    date: "August 5, 2024",
    img: "/img/blog/pregnancy.png",
    content: null,
    subheadings: [
      {
        title: "Weight Gain During Pregnancy",
        subtitle: null,
        content:
          "Pregnancy is a time where it is normal to gain weight. However, gaining too much weight can have a negative effect on you and the baby.  Your dietitian will use your pre-pregnancy weight and current height to calculate your pre-pregnancy body mass index or BMI. Your pre-pregnancy BMI allows your dietitian to calculate how much weight gain is healthy during your pregnancy. ",
        img: null,
        lists: [],
      },
      {
        title: "Be Careful of Gaining too much weight",
        subtitle: null,
        content:
          "Remember, it is normal and healthy to gain some weight in pregnancy, however gaining excessive weight means you might have a very large baby at birth which can be dangerous for you and the child. You might be receiving pressure from your family to ‘eat for two’ but this is a misconception. You need to make healthy food choices but not eat too much. If you have gained too much weight do not go on a diet.",
        img: null,
        lists: [],
      },
      {
        title: "Carbohydrate",
        subtitle: null,
        content:
          "Carbohydrate foods supply the body with energy or fuel and help the baby to grow. Carbohydrate foods also contain several important vitamins and minerals. Eating too much carbohydrate in pregnancy can sometimes lead to complications such as gestational diabetes. However, your doctor will test if you have diabetes. Also, its important to not cut out carbohydrate foods in pregnancy entirely, your dietitian will discuss how much your body and baby needs. Carbohydrate foods include:",
        img: null,
        lists: [
          "Rice and rice products",
          "Bread including roti and chapatti",
          "Potato and sweet potato",
          "Corn",
          "Fruits",
          "Milk, yoghurt and curd",
          "Lentils, kidney beans and chieckpeas",
          "Noodles and pasta",
        ],
      },
      {
        title: "Protein",
        subtitle: null,
        content:
          "Protein is the building blocks of bones, muscles, cartilage, skin and blood and protein is very important to help your baby grow. Protein foods from animal sources also contain high amounts of iron and vitamin B12 which help your with your babies brain and nervous system development. Eat three serves of protein per day in pregnancy, once serve is equal to the following:",
        img: null,
        lists: [
          "1 breast of chicken ",
          "1 hand sized piece of fish (Do not eat large fish in pregnancy)",
          "Palm size piece of pork/lamb/buff",
          "1-2 Eggs- you can eat up to 6-10 per week",
          "150g tofu or paneer ",
          "2 slices hard cheese",
          "30g nuts and seeds ",
          "1 cup Lentils, chickpeas, kidney beans",
        ],
      },
      {
        title: "Fat",
        subtitle: null,
        content:
          "Fat plays an important role in the body supplying vitamins and minerals to you and the baby and supplying energy. You need some fat in your diet in pregnancy, but too much of the wrong type of fat can lead to too much weight gain in pregnancy. ",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content: "Bad fats to avoid are called saturated fats. These include:",
        img: null,
        lists: [
          "Cakes and biscuits",
          "Full fat dairy including cheese",
          "Takeway foods such as KFC, pizza",
          "Fatty cuts of meat",
          "Chicke skin",
          "Butter and ghee and coconut oil",
          "Salami and sausages",
        ],
      },
      {
        title: null,
        subtitle: null,
        content:
          "Good fats to eat called monounsaturated and polyunsaturated. They include: ",
        img: null,
        lists: [
          "Unsalted nuts like cashews, peanuts, walnuts",
          "Seeds like sunflower seeds",
          "Oily fish like salamon or tuna (tinned fish is good too!)",
          "Avocado",
          "Oils like olive oil, canola oil, rice brain oil",
        ],
      },
      {
        title: "Folate",
        subtitle: null,
        content:
          "Folate is very important in your babies brain development. Folate deficiency in pregnancy can lead to complications such as neural tube defects and spina bifida. Folate is in foods such as leafy green vegetables, however its hard to consume the right amount therefore its better to take a folate supplement in pregnancy.  The requirement for folate in pregnancy is 600 micrograms (mcg) per day.",
        img: null,
        lists: [],
      },
      {
        title: "Calcium",
        subtitle: null,
        content:
          "Its important to consume enough dairy foods in pregnancy to ensure your baby has strong bones. If you do not eat enough calcium in pregnancy, the baby will take the calcium from your bones which can lead to problems like osteoporosis in the future. You need to eat three serves of dairy per day in pregnancy to ensure your getting enough calcium which is equal to 1200-1400milligrams. One serve is equal to: ",
        img: null,
        lists: [
          "250ml or 1 cup of cows milk ",
          "250ml or 1 cup of non-dairy milk such as soy milk that has 100mg of calcium per 100g added ",
          "2 slices of hard cheese",
          "150g of paneer ",
          "1 cup curd",
        ],
      },
      {
        title: "Should I take a multivitamin?",
        subtitle: null,
        content:
          "Overall, we recommend getting your nutrients from food. However, in pregnancy it’s a good idea to take a pregnancy multivitamin to be certain you are receiving enough good nutrition. This is especially important for the nutrient, folate. ",
        img: null,
        lists: [],
      },
      {
        title: "Food Safety During Pregnancy ",
        subtitle: null,
        content:
          "In pregnancy its important to be aware of some bad bacteria in foods called listeria or salmonella. So its important to be careful of these foods as they can seriously harm you and the baby. These include: ",
        img: null,
        lists: [
          "Raw meats and deli meats like salami or ham or pate",
          "Fish",
          "Raw seafood and sushi",
          "Large fish as they contain heavy metals like mercury",
          "Raw or soft egg (hard boiled or well cooked egg is safe)",
          "Any products which could have raw egg such as icecream or chocolate mousse ",
          "Soft cheese such as feta ",
          "Premade salads or fruit salad or old salads",
          "Juices from local fruit stalls",
          "Leftovers 1 day old",
          "Unpasteurized dairy products",
        ],
      },
      {
        title: "Vomiting and Nausea",
        subtitle: null,
        content:
          "Often women experience nausea and vomiting in the first trimester, overall this is normal. However some women experience severe nausea and vomiting which can lead to weight loss. If you have experienced this or are currently experiencing this please tell your dietitian and they will make a special eating plan for you. Its important to ensure you are drinking enough fluids, snacking on dry crackers and avoiding foods with a strong smell that cause nausea and vomiting.",
        img: null,
        lists: [],
      },
      {
        title: "Exercise in Pregnancy",
        subtitle: null,
        content:
          "If you have no complications in pregnancy and your doctor has said its safe to do so, doing some light exercise is very healthy for you and the baby. This includes going for a 30 minute walk everyday.",
        img: null,
        lists: [],
      },
      {
        title: "Low Blood Sugars",
        subtitle: null,
        content:
          "If you take certain tablets or insulin, you have a risk of your blood sugars going low. Low blood sugars are a medical emergency. Your sugars could have gone low because of a missed or delayed meal, eating not enough carbohydrate or medication imbalances. Signs of low blood sugars:",
        img: null,
        lists: [
          "Sweaty",
          "Shakey",
          "Headache",
          "Hungry",
          "Confused",
          "Food change or angry",
          "Feel weak or strange ",
        ],
      },
      {
        title: null,
        subtitle: null,
        content: "If you suspect your sugars are low follow these steps:",
        img: null,
        lists: [
          "Tell Someone",
          "Check your blood sugars- if they are less than 90 then you are having low blood sugar",
          "Eat/drink a quick acting sugar like ½ bottle of coke/sprite/fanta or 6 jelly beans/lollies, 1 large spoon of honey ",
          "Wait 10-15 minutes to see how you feel, recheck your blood sugar ",
          "If they have returned to above 90 then eat a more complex carbohydrate like 1 fruit, 1 bread, 1 cup of milk ",
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Healthy Eating for Weight Loss",
    date: "August 5, 2024",
    img: "/img/blog/weight loss.png",
    content: null,
    subheadings: [
      {
        title: "What weight should I aim for?",
        subtitle: null,
        content:
          "While you might have an ‘ideal weight’ in mind, a small amount of weight loss goes a long way. Research shows that loosing 5-10% of your body weight can have significant benefits in your long term health. Its important to set small and realistic goals, your dietitian will calculate the right weight for you and how long it should take you to loose the weight.",
        img: null,
        lists: [],
      },
      {
        title: "Foods with Minimal Calories",
        subtitle: null,
        content:
          "Some foods are so low in calories they will not have an effect on your weight but help to keep you full, these are foods you can eat more of without effecting your weight. These include:",
        img: null,
        lists: [],
      },
      {
        title: "Keeping to a Regular Eating Pattern",
        subtitle: null,
        content:
          "When trying to loose weight, its important to not skip any meals or snacks and to try to eat something small every 2-3 hours, this will help with your hunger cravings and ensure that you do not eat too much food at one time. If your breakfast is at 8am, have a small snack at 10am, lunch at 12pm, have a small snack at 3pm, have dinner around 6pm etc.",
        img: null,
        lists: [],
      },
      {
        title: "Carbohydrate",
        subtitle: null,
        content:
          "Carbohydrates are foods which supply your body with energy or fuel as well as vitamins and minerals. If you eat too much carbohydrate without burning it off, it can be stored as fat in the body and result in weight gain. So its important not to eat too much carbohydrate at one time. Carbohydrates include:",
        img: null,
        lists: [
          "Rice and rice products ",
          "Bread including roti and chapatti",
          "Potato and sweet potato",
          "Corn",
          "Fruits",
          "Milk, yoghurt and curd",
        ],
      },
      {
        title: "Fat",
        subtitle: null,
        content:
          "Fat plays an important role in the body, supplying vitamins and minerals, giving the body insulation and also energy. You need some fat in your diet for general good health, but too much of the wrong type of fat can cause weight gain and stop weight loss. Be careful about eating too much good fat as it can slow or stop your weight loss.",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content: "Bad fats to avoid are called saturated fats. These include:",
        img: null,
        lists: [
          "Cakes and biscuits",
          "Full fat dairy including cheese",
          "Takeway foods such as KFC, pizza",
          "Fatty cuts of meat",
          "Chicke skin",
          "Butter and ghee and coconut oil",
          "Salami and sausages",
        ],
      },
      {
        title: null,
        subtitle: null,
        content:
          "Good fats to eat called monounsaturated and polyunsaturated. They include: ",
        img: null,
        lists: [
          "Unsalted nuts like cashews, peanuts, walnuts",
          "Seeds like sunflower seeds",
          "Oily fish like salamon or tuna (tinned fish is good too!)",
          "Avocado",
          "Oils like olive oil, canola oil, rice brain oil",
        ],
      },
      {
        title: "Protein",
        subtitle: null,
        content:
          "Your body uses protein to build and repair tissues, make enzymes, hormones and other chemicals. Protein is the building blocks of bones, muscles, cartilage, skin and blood. Protein plays a big role in weight loss as it can help keep you full for longer and stop hunger cravings. You should try to add some protein to each of your meals and snacks. Foods high in protein include:",
        img: null,
        lists: [
          "Chicken",
          "Fish",
          "Pork and other meats",
          "Eggs",
          "Paneer and cheese",
          "Nuts and Seeds",
        ],
      },
      {
        title: "Foods to Avoid",
        subtitle: null,
        content:
          "When trying to loose weight its important to avoid foods which are high in calories. This is usually junk food such as chips, lollies, biscuits, cakes, pastries, packets of instant noodles, burgers, pizza, fried foods, icecream, sausages and salami.",
        img: null,
        lists: [],
      },
      {
        title: "Think about your drinks",
        subtitle: null,
        content:
          "Don’t forget that some drinks also have high calories, so its best to avoid them. This includes, coke, sprite, fanta, alcohol, milkshakes, coffees with added icecream and even juice. Overall the best drink to have is water and low fat milk for calcium.",
        img: null,
        lists: [],
      },
    ],
  },
  {
    id: 6,
    title: "Sports & Gym Nutrition Information",
    date: "August 5, 2024",
    img: "/img/blog/sprout.png",
    content: null,
    subheadings: [
      {
        title: "Energy Requirements",
        subtitle: null,
        content:
          "Energy requirements for people who undertake exercise and wish to build their body varies quite a lot depending upon the person and what type of exercise they do. Daily requirements fluctuate according to the type, duration, intensity, frequency of training and other lifestyle/health factors. ",
        img: null,
        lists: [
          "Endurance => 130-230 kcal/kg/day",
          "Sprint => 100-180kcal/kg/day ",
          "Skill => 100-180kcal/kg/day ",
          "Team => 120-200kcal/kg/",
          "Weight cat. => 100-200kcal/kg/day ",
        ],
      },
      {
        title: "Protein Requirements",
        subtitle: null,
        content:
          "What determines how much protein your body needs is your training schedule and normal nutrient intake. Protein post-exercise appears to enhance muscle uptake, amino acid retention and protein balance. Combining carbohydrate with protein following exercise, appears to improve the effects of protein. The Australian Institute of Sport (AIS) reports that a realistic increase in body mass is 2-4kg per month, and to achieve muscle mass increase, protein intake should be 1.2-2.0g/kg/day, and energy should be 400kcals above recommendations per day. Foods with 10g of Protein:",
        img: null,
        lists: [
          "Meat and Alternative",
          "Dairy Foods",
          "Nuts and Legumes",
          "Grains",
        ],
      },
      {
        title: "Increasing Muscle Mass",
        subtitle: null,
        content: null,
        img: null,
        lists: [
          "Incorporate a muscle-growth phase into training schedule including resistance training. Reduce fitness and conditioning sessions.",
          "Diet should be based on carbohydrate dense foods for energy with small serves of protein foods at each snack and main meal, especially pre and post training. ",
          "Increase meal and snack frequency to ensure energy and protein requirements are met.",
          "Include 1g carbohydrate/kg/body weight + 10-20g protein within 30 minutes of finishing training",
          "If you are doing intense exercise or are an athlete, make the most of energy-containing drinks e.g. Gatorade to achieve fluid and energy needs. However, this is not a requirement. ",
          "Ensure there is good organization for implementing the diet plan, ensure foods are readily available and easy to prepare",
          "After the diet has been implemented, if needs are still not met consider a protein powder. ",
          "High protein intakes of >2g/kg/day do not improve muscle growth or recovery",
        ],
      },
      {
        title: "Fat Requirements",
        subtitle: null,
        content:
          "Fat should be 25% of total energy requirements. Fat is a good source of fuel when there isn’t being enough carbohydrate consumed. However, if you are eating too much fat and carbohydrate, your body will store extra fat. Include healthy fats which come from poly/monounsaturated sources including fish, avocado, oils such as olive oil/canola/rice bran/sunflower, nuts and seeds. Avoid foods high in saturated fat such as fat from animals, processed/takeaway foods, salami/sausages, coconut oil.",
        img: null,
        lists: [],
      },
      {
        title: "Carbohydrate Requirements",
        subtitle: null,
        content:
          "Carbohydrate is the main source of fuel used during exercise. Carbohydrate helps build fuel reserves, delay fatigue and improve recovery. Requirements of carbohydrate vary and are affected by length, intensity and frequency of training as well as other lifestyle factors. When you are exercising for 1 hour or more, carbohydrate is the most important for your performance. Therefore, including carbohydrate before, after and sometimes during exercise is important. Food serves with Carbohydrate:",
        img: null,
        lists: [
          "Bread and Cerals",
          "Fruit",
          "Dairy Food",
          "Starchy Vegetables and Legumes",
        ],
      },
      {
        title: "Fluid Requirements",
        subtitle: null,
        content:
          "Its important to replace fluid that you might have lost during sweating when working out. If you are ‘loosing’ weight after exercise then that is related to loss of fluid. ",
        img: null,
        lists: [],
      },
    ],
  },
  {
    id: 7,
    title: "Lets Talk Sprouts",
    date: "August 5, 2024",
    img: "/img/blog/sprout.png",
    content: null,
    subheadings: [
      {
        title: "What are Sprouts?",
        subtitle: null,
        content:
          "Sprout are those germinated seeds that are kept in right moisture & temperature yielding young plants of length 2-5 cm. They could be sprout of grains, beans, vegetable & nuts.",
        img: null,
        lists: [],
      },
      {
        title: "Why to eat sprouts",
        subtitle: null,
        content: null,
        img: null,
        lists: [
          "High protein contents",
          "Rich in vitamins C & K, mineral like folate, magnesium, phosphorus, etc. ",
          "Sprouts help to decrease the amount of anti-nutrient found in beans(Un-soaked) thereby increasing absorption of nutrients in the body.",
          "Aids in digestion due to presence of fiber in them.",
          "Good for control of blood sugar level.",
          "Improve heart health by maintain cholesterol & triglycerides level.",
        ],
      },
      {
        title: "How to take sprouts?",
        subtitle: null,
        content: null,
        img: null,
        lists: ["Salads", "Soup/quati", "Pancakes", "Chutney"],
      },
      {
        title: "How much can be taken?",
        subtitle: null,
        content:
          "1-2 Handful of well sprouted beans should be enough for adult to stay healthy.",
        img: null,
        lists: [],
      },
      {
        title: "How to Sprout beans?",
        subtitle: null,
        content: null,
        img: null,
        lists: [
          "Wash beans thoroughly, soaked it with water for 8-12 hours.",
          "Wash the soaked beans thoroughly since that can be slimy/ remove skin of beans.Take clean clothes & dip them in water. Put that soaked beans in that clothes.Wrapped it properly. Put them in cool place in bowl. ",
          "Beans in the cloths must be washed twice a day for them to be well sprouted. ",
          "Since the temperature is hot, you will get well sprouted beans within 4-5 days. Before consumption wash them properly. For preservation you can keep them in fridge for a day or two.",
        ],
      },
    ],
  },
  {
    id: 8,
    title: "Thyroid and Nutrition",
    date: "August 5, 2024",
    img: "/img/blog/thyroid.png",
    content: null,
    subheadings: [
      {
        title: "What is hypothyroidism?",
        subtitle: null,
        content:
          "Hypothyroidism is a specific type of thyroid condition that is generally characterized by an under-active thyroid gland or the inability to convert inactive thyroid hormone to active thyroid hormone that is used by the cells of the body. The thyroid is a butterfly shaped gland that sits just above the point where your clavicles meet (in your lower neck) and is one of the primary organs responsible for regulating your metabolism. Most of us think of our metabolism simply as how much fat we’re able to burn but actually it can influence the function of every cell in our body and how those cells utilize the body’s resources. So with this in mind, it’s easy to see how a sluggish thyroid can dramatically hinder the overall function of the body. Those who have this condition will typically experience symptoms like:",
        img: null,
        lists: [
          "Unexplained weight gain",
          "Constant tiredness",
          "Muscle soreness and pain",
          "Dry and flaky skin",
          "High cholesterol levels",
          "Poor concentration and memory",
          "Menstrual problems and fertiltiy problems",
        ],
      },
      {
        title: "Treatment",
        subtitle: null,
        content:
          "Often your doctor will decide to put you on a thyroid hormone tablet like thyroxine to balance out your thyroid levels in your blood. Things you can do to help in terms of your diet include:",
        img: null,
        lists: [
          "Being a healthy weight or losing weight if you are overweight. But important to set realistic goals as weight loss can be hard with thyroid.",
          "Eat plenty of fruits and vegetables",
          "Control your intake of carbohydrates",
          "Consume enough iodine",
          "Get enough Vitamin D. The sun is the best source",
          "Eat enough foods high in selenium such as nuts and tuna",
          "Eat foods which contain Vitamin B12 such as red meat and eggs",
          "If you have an iodine deficiency be careful about eating too much broccoli and cauliflower",
        ],
      },
      {
        title: "Exercise",
        subtitle: null,
        content:
          "Doing regular exercise should be apart of your treatment plan for thyroid. Aim for 30 minutes per day, if you are experiencing tiredness this can be difficult so try to break it up into 3 lots of 10 minute exercises. Examples of good exercises include: ",
        img: null,
        lists: [
          "Gardening",
          "Walking or jogging",
          "Washing windows",
          "Raking up leaves",
          "Shopping",
          "Vacuuming/Washing floors",
        ],
      },
    ],
  },
  {
    id: 9,
    title: "Nutrition for older adults",
    date: "August 5, 2024",
    img: "/img/blog/oldnutrition.png",
    content: null,
    subheadings: [
      {
        title: "What is nutrition and why is it important for older adults?",
        subtitle: null,
        content:
          "Nutrition is about eating a healthy and balanced diet so your body gets the nutrients that it needs. Nutrients are substances in foods that our bodies need so they can function and grow. They include carbohydrates, fats, proteins, vitamins, minerals, and water. Good nutrition is important, no matter what your age. It gives you energy and can help you control your weight. It may also help prevent some diseases, such as osteoporosis, high blood pressure, heart disease, type 2 diabetes, and certain cancers. But as you age, your body and life change, and so does what you need to stay healthy. For example, you may need fewer calories, but you still need to get enough nutrients. Some older adults need more protein. Some changes that can happen as you age can make it harder for you to eat healthy. These include changes in Home life, such as suddenly living alone or having trouble getting around Health, which can make it harder to cook or feed yourself. Medicines, which can change how food tastes, make your mouth dry, or take away your appetite. Income, which means that you may not have as much money for food. Sense of smell and taste Problems chewing or swallowing your food.",
        img: null,
        lists: [],
      },
      {
        title: "To stay healthy as you age, you should:",
        subtitle: null,
        content:
          "Eat foods that give you lots of nutrients without a lot of extra calories, such as Fruits and vegetables (choose different types with bright colors)Whole grains, like oatmeal, whole-wheat bread, and brown rice. Fat-free or low-fat milk and cheese, or soy or rice milk that has added vitamin D and calcium. Lean meats, poultry, and eggs Beans, nuts, and seeds. Avoid empty calories. These are foods with lots of calories but few nutrients, such as chips, candy, baked goods, soda, and alcohol. Pick foods that are low in cholesterol and fat. You especially want to try to avoid saturated and trans fats. Saturated fats are usually fats that come from animals. Trans fats are processed fats in stick margarine and vegetable shortening. You may find them in some store-bought baked goods and fried foods at some fast-food restaurants. Drink enough liquids, so you don't get dehydrated. Some people lose their sense of thirst as they age. And certain medicines might make it even more important to have plenty of fluids.Be physically active. If you have started losing your appetite, exercising may help you to feel hungrier.",
        img: null,
        lists: [],
      },
      {
        title: "What can you do if you are having trouble eating healthy?",
        subtitle: null,
        content:
          "Sometimes health issues or other problems can make it hard to eat healthy. Here are some tips that might help:If you are tired of eating alone, try organizing some potluck meals or cooking with a friend. You can also look into having some meals at a nearby senior center, community center, or religious facility. If you are having trouble chewing, see your dentist to check for problems. If you are having trouble swallowing, try drinking plenty of liquids with your meal. If that does not help, check with your health care provider. A health condition or medicine could be causing the problem. If you're having trouble smelling and tasting your food, try adding color and texture to make your food more interesting. If you aren't eating enough, add some healthy snacks throughout the day to help you get more nutrients and calories. If an illness is making it harder for you to cook or feed yourself, check with your health care provider. He or she may recommend an occupational therapist, who can help you find ways to make it easier. ",
        img: null,
        lists: [],
      },
    ],
  },
  {
    id: 10,
    title: "Benefits of mint in summer",
    date: "August 5, 2024",
    img: "/img/blog/mint.png",
    content: null,
    subheadings: [
      {
        title: "What is mint? ",
        subtitle: null,
        content:
          "Mint or Pudina is a kind of herbs having aromatic texture & many health benefits. They provide cool sensation & can be taken in various ways.",
        img: null,
        lists: [],
      },
      {
        title: "What can be done of Mint? ",
        subtitle: null,
        content: null,
        img: null,
        lists: [
          "Beverages like mint tea, lemonade, detox water, etc",
          "Mint Chutney",
          "Mint leaves",
          "Act as garnish in salads yoghurt, soup, chat, etc",
        ],
      },
      {
        title: "Why to include mint in diet?",
        subtitle: null,
        content: null,
        img: null,
        lists: [
          "RICH IN NUTRIENTS: Since almost zero calories herb (14gm of mint=6 calories) but rich in Vitamin A, iron, folate, manganese, etc.",
          "GOOD FOR GASTRO- INTESTINAL HEALTH: Relieve from pain, bloating, gas or bowel pattern.",
          "TACKLE WITH COLD: Un easiness in nose is ceases by using methanol found in mint & help in normal breathing. Though this act as subjectively.",
          ". FOUL BREATHE can be mask up & kill those bacteria in the mouth by eating/ sipping mint tea rather than consuming sugary chew gum. ",
        ],
      },
      {
        content:
          "Though study have shown benefits of mints are greatly obtained by means of capsules, oils or aroma therapy, etc but having them in food can be advantageous too. Lastly mint can be easily grown in our climatic condition be it in garden or in pot.But make sure to wash them throughly before consumption.",
      },
    ],
  },
  {
    id: 11,
    title: "Nutritional value of mushroom",
    date: "August 5, 2024",
    img: "/img/blog/mushroom.png",
    content: null,
    subheadings: [
      {
        title: "What is the health and Nutritional value of the mushroom?",
        subtitle: null,
        content:
          "The key to getting enough Vitamins and minerals in the diet is to eat a colorful variety of fruits and vegetables. In many cases, a food that lacks color also lacks necessary nutrients, but edible mushrooms, which are commonly white, prove quite the contrary Mushrooms are classified as vegetables in the food world, but they are not technically plants. They belong to the fungi kingdom. Although they are not vegetables, mushroom provides several important nutrients. Mushrooms may lack the deep green or brilliant red hues consumers have come to associate with nutrient-rich fruits and vegetables, but they are a “powerhouse of nutrition” and not a white food to be avoided stated by many of the dieticians from U.S.A. Low in calories and fat and cholesterol-free, mushrooms contain a modest amount of fiber and over a dozen minerals and vitamins, including copper, potassium, magnesium, zinc and a number of B vitamins such as folate. Mushrooms are also high in antioxidants like selenium and glutathione, or GSH, substances believed to protect cells from damage and reduce chronic disease and inflammation.",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content:
          "Some studies suggest mushrooms are the richest dietary source of another antioxidant called ergothioneine, or ERGO, which is also present in large amounts in red beans, oat bran and liver. ERGO and other antioxidants are primarily concentrated in the caps, not the stems.",
        img: null,
        lists: [],
      },
      {
        title: null,
        subtitle: null,
        content:
          "But the nutrient profile of a mushroom varies depending on the type and the method of cultivation. It is also a good source of Dietary Fiber, Protein, Vitamin C, Folate, Iron, Zinc and Manganese, and a very good source of Vitamin D, Thiamin, Riboflavin, Niacin, Vitamin B6, Pantothenic Acid, Phosphorus, Potassium, Copper and Selenium. Increasing consumption of whole, unprocessed foods, like mushrooms, appears to decrease the risk of obesity and overall mortality, diabetes, and heart disease. They also promote a healthy complexion and hair, increased energy, and overall lower weight. ",
        img: null,
        lists: [],
      },
      {
        content:
          "Mushroom are high in antioxidant, just like carrots, tomatoes, green and red peppers, Pumpkins, green beans, zucchini, and other whole foods. Antioxidants are chemicals that get rid of free radicals, a type of chemical that can harm a person's body cells, potentially leading to cancer. Studies have shown that people with type 1 diabetes who consume high-fiber diets have lower blood glucose levels and people with type 2 diabetes may have improved blood sugar, lipids and insulin levels.  ",
      },
      {
        content:
          "The fiber, potassium and vitamin C content in mushrooms all contribute to cardiovascular health. Potassium and sodium work together in the body to help regulate blood pressure. Consuming mushrooms, which are high in potassium and low in sodium, helps to lower blood pressure and decrease the risk of high blood pressure and cardiovascular diseases. Selenium has also been found to improve immune response to infection by stimulating the production of killer T-cells. The beta-glucan fibers found in the cell walls of mushrooms stimulate the immune system to fight cancer cells and prevent tumors from forming. ",
      },
      {
        content:
          "Dietary fiber plays an important role in weight management by functioning as a 'bulking agent' in the digestive system. Mushrooms contain two types of dietary fibers in their cell walls, beta-glucans and chitin. These increase satiety and reduce appetite. By making you feel fuller longer, they can reduce overall calorie intake.",
      },
    ],
  },
];

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find((post) => post.id === parseInt(id));

  if (!post) {
    return (
      <div className="vh-100">
        <h2 className="d-flex justify-content-center mt-5 text-danger">
          Blog post not found
        </h2>
      </div>
    );
  }

  return (
    <div className="container mt-4 mb-5 mt-md-5 blogpost-section">
      <h2>{post.title}</h2>
      <p className="text-muted">{post.date}</p>
      <img
        src={post.img}
        alt={post.title}
        className="w-100 rounded mb-2 blogpost-img"
      />
      <p className=" text-justify">{post.content}</p>
      {post.subheadings.map((subheading, index) => {
        const isImageFirst = index % 2 === 1;
        return (
          <div
            className={`row mx-auto ${subheading.title ? "pt-5" : ""} ${
              isImageFirst ? " flex-row-reverse" : ""
            }`}
            key={index}
          >
            <div className="col">
              <h3>{subheading.title}</h3>
              <p className="text-justify pe-md-5">{subheading.content}</p>
              {subheading.subtitle && (
                <h5 className="text-decoration-underline">
                  {subheading.subtitle}
                </h5>
              )}

              {subheading.lists && (
                <div>
                  {subheading.lists.map((foodItem, index) => (
                    <div key={index}>
                      <IoCheckmarkCircle className="me-2" />
                      {foodItem}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {subheading.img && (
              <div className="col-md ">
                <img
                  src={subheading.img}
                  alt={subheading.title}
                  className=" rounded subheading-img"
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BlogPost;
