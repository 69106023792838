import React, { useState } from "react";
import { Card, Modal, Button, Carousel } from "react-bootstrap";

const Media = () => {
  const logos = [
    {
      src: "/img/logos/ok.png",
      alt: "Online Khabar logo",
    },

    {
      src: "/img/logos/nari.png",
      alt: "Nari logo",
    },
    {
      src: "/img/logos/janatahd.png",
      alt: "Janata Hd logo",
    },
    {
      src: "/img/logos/yoho.png",
      alt: "Prime Hd logo",
    },
    {
      src: "img/logos/lokpath.jpg",
      alt: "Lokpath Tv logo",
    },
    {
      src: "img/logos/primehd.jpg",
      alt: "Prime Hd logo",
    },
    {
      src: "img/logos/annapurna.png",
      alt: "The Annpurna Express logo",
    },
  ];

  const youtubeVideos = [
    {
      id: 1,
      title: "Benefits of Veg and Non-veg?",
      date: "August 4, 2024",
      videoId: "Rfa6FS9haRs",
      channel: "Yoho Television HD",
    },
    {
      id: 2,
      title: "Chiya Guff",
      date: "Oct 13, 2021",
      videoId: "2fT-a4D48m4",
      channel: "Prime HD",
    },
    {
      id: 3,
      title: "Balanced Diet",
      date: "May 25, 2023",
      videoId: "AhfHIx8iq7A",
      channel: "Janata Television",
    },
    {
      id: 4,
      title: "Negative Effects of Keto Diet",
      date: "Dec 24, 2022",
      videoId: "OTnthNGgZZw",
      channel: "Global TV HD",
    },
    {
      id: 5,
      title: "Batch No 5",
      date: "Dec 22, 2022",
      videoId: "1930JSGgyU0",
      channel: "Prime Times HD",
    },
    {
      id: 6,
      title: "Chiya Guff",
      date: "Oct 4, 2022",
      videoId: "2fT-a4D48m4",
      channel: "Prime Times HD",
    },
    {
      id: 7,
      title: "Tokha Health Camp",
      date: "Sep 5, 2022",
      videoId: "8wsFXebNrgw",
      channel: "The Nutrition Cure Diet Clinic",
    },
    {
      id: 8,
      title: "School Nutrition Project",
      date: "Aug 19, 2022",
      videoId: "H3goMgaAIFY",
      channel: "The Nutrition Cure Diet Clinic",
    },
    {
      id: 9,
      title: "Effects of Daily Food on Health",
      date: "August 12, 2022",
      videoId: "pwSmomb-z98",
      channel: "TV Full Masti",
    },
    {
      id: 10,
      title: "Effects of Keto Diet and Fasting",
      date: "August 7, 2022",
      videoId: "XETJal5dHFY",
      channel: "Yoho Television HD",
    },
    {
      id: 11,
      title: "Festival's Food for Uric Acid Patient",
      date: "Oct 12, 2021",
      videoId: "I9Q4VTjpvP4",
      channel: "Lokpath TV",
    },
    {
      id: 12,
      title: "Food intake during Festivals",
      date: "Oct 11, 2021",
      videoId: "B7COtdtOqMI",
      channel: "Lokpath TV",
    },
    {
      id: 13,
      title: "Diseases caused by Food Habits",
      date: "June 20, 2021",
      videoId: "2TAXwqr05ho",
      channel: "Lokpath TV",
    },
    {
      id: 14,
      title: "Global Health",
      date: "Aug 9, 2021",
      videoId: "ljdxON-_JM8",
      channel: "Lokpath TV",
    },
    {
      id: 15,
      title: "Balanced Diet",
      date: "June 11, 2021",
      videoId: "TY02HQ5OxZo",
      channel: "Lokpath TV",
    },
  ];

  const newsArticles = [
    {
      id: 1,
      title: "हामीले छाकैपिच्छे खाने तेल कसरी विषाक्त हुन्छ ?",
      date: "August 4, 2024",
      img: "/img/article/article1.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/01/1250475",
    },
    {
      id: 2,
      title: "सहरमा बस्नेलाई किन जरुरी छ भिटामिन डी सप्लिमेन्ट ?",
      date: "२०८१ जेठ ३",
      img: "/img/article/article2.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2024/05/1480191",
    },
    {
      id: 3,
      title: "‘फ्याट’सँग सम्बन्धित तीन भ्रम",
      date: "२०८० फागुन २१",
      img: "/img/article/article3.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2024/03/1444262",
    },
    {
      id: 4,
      title: "केचप नियमित खाँदा स्वास्थ्यमा ल्याउँछ समस्या",
      date: "२०८० साउन ४",
      img: "/img/article/article4.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/07/1339019",
    },
    {
      id: 5,
      title: "अचार : स्वादका लागि मात्रै कि गुणकारी पनि?",
      date: "२०८० असार २१",
      img: "/img/article/article5.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/07/1332434",
    },
    {
      id: 6,
      title: "किन खाने सलाद ?",
      date: "२०८० असार १८",
      img: "/img/article/article6.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/07/1331100",
    },
    {
      id: 7,
      title: "वर्षायाममा कस्तो खानेकुरा खाने ?",
      date: "२०८० असार ३",
      img: "/img/article/article7.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/06/1323838",
    },
    {
      id: 8,
      title: "च्याउमा के पाइन्छ, कसरी खाने ?",
      date: "२०८० वैशाख ३१",
      img: "/img/article/article8.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/05/1306489",
    },
    {
      id: 9,
      title: "अरु चामलभन्दा ब्राउन राइस कसरी स्वस्थकर?",
      date: " २०८० वैशाख २०",
      img: "/img/article/article9.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/05/1301575",
    },
    {
      id: 10,
      title: "शुद्ध तेल चिन्ने कसरी ?",
      date: "२०७९ चैत १९",
      img: "/img/article/article10.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2023/04/1286359",
    },
    {
      id: 11,
      title: "किटो डाइट शरीर सुहाउँदो भोजन होइन",
      date: "२०७९ पुष ३",
      img: "/img/article/article11.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2022/12/1234147",
    },
    {
      id: 12,
      title: "‘कोल्ड ड्रिंक’ वास्तवमै पिउन योग्य हुन्न ?",
      date: "२०७९ भदौ १०",
      img: "/img/article/article12.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2022/08/1180544",
    },
    {
      id: 13,
      title: "Food habits: The one thing you can and must fix",
      date: "Aug. 26, 2022",
      img: "/img/article/article13.jpg",
      portal: "The Annapurna Express",
      link: "https://theannapurnaexpress.com/news/food-habits-the-one-thing-you-can-and-must-fix-29542/?fbclid=IwZXh0bgNhZW0CMTEAAR1W9aCL-gA906BhsRGUn-l-bUBcdHe6e9MKoXwWhVot8MyIsgIYawLpiNE_aem_Pz478S3kWkUxKgjvHC8sFQ",
    },
    {
      id: 14,
      title: "उपवासबाट उपचार",
      date: "भाद्र ५, २०७९",
      img: "/img/article/article14.png",
      portal: "Nari Mag",
      link: "https://narimag.com.np/health/2022/08/21/20220819143801.html",
    },
    {
      id: 15,
      title: "१६ घण्टे उपवास हानिकारक पनि हुनसक्छ : पोषणविद्",
      date: "२०७९ जेठ ३१",
      img: "/img/article/article15.jpg",
      portal: "Online Khabar",
      link: "https://www.onlinekhabar.com/2022/06/1142226",
    },
  ];
  // const albums = [
  //   {
  //     id: 1,
  //     title: "Gym",
  //     images: [
  //       "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253498/pexels-photo-3253498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/4720792/pexels-photo-4720792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/7289241/pexels-photo-7289241.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1092877/pexels-photo-1092877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Exercise",
  //     images: [
  //       "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253498/pexels-photo-3253498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/4720792/pexels-photo-4720792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/7289241/pexels-photo-7289241.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1092877/pexels-photo-1092877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //     ],
  //   },
  //   {
  //     id: 3,
  //     title: "Training",
  //     images: [
  //       "https://images.pexels.com/photos/3253498/pexels-photo-3253498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/4720792/pexels-photo-4720792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/7289241/pexels-photo-7289241.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1092877/pexels-photo-1092877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //     ],
  //   },
  //   {
  //     id: 4,
  //     title: "Hustle",
  //     images: [
  //       "https://images.pexels.com/photos/4720792/pexels-photo-4720792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/7289241/pexels-photo-7289241.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1092877/pexels-photo-1092877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253498/pexels-photo-3253498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //     ],
  //   },
  //   {
  //     id: 5,
  //     title: "Grind",
  //     images: [
  //       "https://images.pexels.com/photos/1092877/pexels-photo-1092877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253498/pexels-photo-3253498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/4720792/pexels-photo-4720792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/7289241/pexels-photo-7289241.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //     ],
  //   },
  //   {
  //     id: 6,
  //     title: "Workout",
  //     images: [
  //       "https://images.pexels.com/photos/7289241/pexels-photo-7289241.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1092877/pexels-photo-1092877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/1954524/pexels-photo-1954524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/3253498/pexels-photo-3253498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //       "https://images.pexels.com/photos/4720792/pexels-photo-4720792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  //     ],
  //   },
  // ];

  const [activeTab, setActiveTab] = useState("video");
  const [showModal, setShowModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [modalType, setModalType] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleShowModal = (media, type) => {
    setSelectedMedia(media);
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMedia(null);
    setModalType("");
  };

  return (
    <>
      <section className="py-5">
        <div className="container">
          <h2 className="fw-bold text-center">
            Featured
            <span className="primary-text-color"> In</span>
          </h2>
          <p className="text-center mb-3 mb-md-5 fw-medium container">
            Explore the media coverage of Nutrition Cure!
          </p>
          <div className="row justify-content-center">
            {logos.map((logo, index) => (
              <div
                className="col-4 col-sm-4 col-md-2 col-lg-3 col-xl-1 d-flex align-items-center justify-content-center mb-3"
                key={index}
              >
                <img src={logo.src} alt={logo.alt} className="img-fluid logo" />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container py-4 py-md-5">
          <div className="text-center mb-4">
            <button
              className={`primary-button mx-2 display-block  px-5 mb-3 mb-md-0 ${
                activeTab === "video"
                  ? "active bg-white primary-text-color border-top shadow-sm border-success"
                  : ""
              }`}
              onClick={() => handleTabChange("video")}
            >
              Video
            </button>
            <button
              className={`primary-button px-5 py-2 mx-2 mb-3 mb-md-0 ${
                activeTab === "article"
                  ? "active bg-white primary-text-color border-top shadow-sm border-success"
                  : ""
              }`}
              onClick={() => handleTabChange("article")}
            >
              Article
            </button>
            {/* <button
              className={`primary-button px-5 py-2 mx-2 mb-3 mb-md-0 ${
                activeTab === "gallery"
                  ? "active bg-white primary-text-color border-top shadow-sm border-success"
                  : ""
              }`}
              onClick={() => handleTabChange("gallery")}
            >
              Gallery
            </button> */}
          </div>
          <div className="row">
            {activeTab === "video" &&
              youtubeVideos.map((post) => (
                <div className="col-md-4 mb-4" key={post.id}>
                  <VideoCard
                    post={post}
                    onShowModal={(media) => handleShowModal(media, "video")}
                  />
                </div>
              ))}
            {activeTab === "article" &&
              newsArticles.map((post) => (
                <div className="col-md-4 mb-4" key={post.id}>
                  <ArticleCard post={post} />
                </div>
              ))}
            {/* {activeTab === "gallery" &&
              albums.map((album) => (
                <div className="col-md-4 mb-4" key={album.id}>
                  <GalleryCard
                    album={album}
                    onShowModal={(media) => handleShowModal(media, "gallery")}
                  />
                </div>
              ))} */}
          </div>
        </div>
      </section>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Body>
          {modalType === "video" && selectedMedia && (
            <iframe
              width="100%"
              height="500"
              src={`https://www.youtube.com/embed/${selectedMedia.videoId}`}
              title={selectedMedia.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          {modalType === "gallery" && selectedMedia && (
            <Carousel>
              {selectedMedia.images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    src={image}
                    alt={`Album ${index}`}
                    className="d-block w-100"
                    style={{ height: "500px", objectFit: "cover" }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const VideoCard = ({ post, onShowModal }) => {
  return (
    <Card className="media-card" onClick={() => onShowModal(post)}>
      <div className="video-container">
        <img
          src={`https://img.youtube.com/vi/${post.videoId}/maxresdefault.jpg`}
          alt={post.title}
          className="card-img-top"
        />
        <img
          src="/img/assets/youtube.png"
          alt="YouTube Logo"
          className="youtube-logo"
        />
      </div>
      <Card.Body className="fw-medium">
        <div>
          <span className="primary-text-color">Topic:</span> {post.title}
        </div>
        <div>
          <span className="primary-text-color">Channel:</span> {post.channel}
        </div>
        <div>
          <span className="primary-text-color">Date:</span>{" "}
          <small className="text-muted">{post.date}</small>
        </div>
      </Card.Body>
    </Card>
  );
};

const ArticleCard = ({ post }) => {
  return (
    <Card className="media-card">
      <a href={post.link} target="_blank" rel="noreferrer">
        <div className="video-container">
          <Card.Img variant="top" src={post.img} className="blog-card-img" />
        </div>
        <Card.Body className="fw-medium text-dark card-body">
          <div>
            <span className="primary-text-color">Topic:</span>
            <span id="unicode-bold"> {post.title}</span>
          </div>
          <div>
            <span className="primary-text-color">Portal:</span> {post.portal}
          </div>
          <div>
            <span className="primary-text-color">Date:</span>{" "}
            <small className="text-muted fw-normal">{post.date}</small>
          </div>
        </Card.Body>
      </a>
    </Card>
  );
};

const GalleryCard = ({ album, onShowModal }) => {
  const [firstImage, ...restImages] = album.images;

  const remainingImagesCount = restImages.length - 1;

  return (
    <Card className="media-card m-2" onClick={() => onShowModal(album)}>
      <Card.Body className="p-0">
        <div className="row">
          <div className="col-6 pe-1">
            <img
              src={firstImage}
              alt={album.title}
              className=" w-100 h-100 object-fit-cover"
            />
          </div>
          <div className="col-6 ps-0 d-flex flex-column">
            {restImages.slice(0, 2).map((image, index) => (
              <div key={index} className="position-relative">
                <img
                  src={image}
                  alt={`${album.title} ${index + 1}`}
                  className={`w-100 ${index === 0 ? "pb-1" : "pb-0"}`}
                />
                {index === 1 && (
                  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50 text-white">
                    <span className="fs-5">+{remainingImagesCount}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="text-muted text-center py-3 shadow-sm">
        <span className="primary-text-color fw-medium">Album:</span>
        <span className="text-dark"> {album.title} </span>
      </Card.Footer>
    </Card>
  );
};

export default Media;
