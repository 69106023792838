import React from "react";
import Hero from "./Hero";
const AboutUs = () => {
  return (
    <section className="py-5">
      <h2 className="fw-bold text-center ">
        About
        <span className="primary-text-color"> Us</span>
      </h2>
      <p className="text-center pb-md-3 fw-medium ">
        Fuel Your Life with Balanced Nutrition!
      </p>
      <Hero />
    </section>
  );
};

export default AboutUs;
