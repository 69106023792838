import React from "react";
import Slider from "./Slider";
import FeatureCard from "./Features";
import WhyUs from "./WhyUs";
import Pricing from "./Pricing";
import Hero from "./Hero";
import Testimonials from "./Testimonials";

const Home = () => {
  return (
    <>
      <Slider />
      <FeatureCard />
      <WhyUs />
      <Pricing />
      {/* <Hero /> */}
      <Testimonials />
    </>
  );
};

export default Home;
