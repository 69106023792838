import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkMessenger = () => {
  return (
    <div className="App">
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
      />
    </div>
  );
};

export default TawkMessenger;
