import React, { useState } from "react";
import { useFormContext } from "./FormContext";
import BookAppointment from "./BookAppointment";
import FormSubmissionHandler from "../services/FormSubmissionHandler";
import AlertMessage from "./AlertMessage";
import PhoneNumberInput from "./PhoneNumberInput";
import compareCalendlyWithTemporaryTable from "../services/compareCalendlyWithTemporaryTable";

const AppointmentForm = () => {
  const { updateFormData } = useFormContext();
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "",
    image: null,
  });
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setForm((prevForm) => ({ ...prevForm, [name]: files[0] }));
    } else {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!form.name) errors.name = "Name is required";
    if (!form.email) errors.email = "Email is required";
    // if (!form.phone) errors.phone = "Phone number is required";
    // if (!form.countryCode) errors.countryCode = "Country code is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    updateFormData(form);
    setFormSubmitted(true);
    try {
      const res = await FormSubmissionHandler({
        fullName: form.name,
        email: form.email,
        phone: form.phone,
        message: "", // Include message if applicable
        pageDetails: "booking",
        imageFile: form.image,
      });
      if (res.errors) {
        setAlert({ type: "danger", message: res.errors });
      } else {
        // setAlert({ type: "success", message: "Form submitted successfully" });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 3000);
      }

      // Compare Calendly data with the temporary table
      const comparisonResult = await compareCalendlyWithTemporaryTable();

      if (comparisonResult) {
        // Handle comparison result
        console.log("Comparison result:", comparisonResult);
        // Show some alert or notification if necessary
      }
    } catch (error) {
      setAlert({
        type: "danger",
        message: "Somthing went wrong please try again!",
      });
      console.error("Error submitting form:", error);
    }
  };

  const handleCloseAlert = () => {
    setAlert({ type: "", message: "" });
  };

  return (
    <>
      <div className="col-12 col-md-5 ms-md-4 mt-md-5 mt-md-0">
        {alert.message && (
          <AlertMessage
            type={alert.type}
            message={alert.message}
            onClose={handleCloseAlert}
          />
        )}
      </div>
      <div className="my-5 container">
        <h2 className="fw-bold text-center ">
          Book
          <span className="primary-text-color"> Appointment</span>
        </h2>
        <p className="text-center fw-medium mb-4">Book your appointment now!</p>

        {formSubmitted ? (
          <BookAppointment />
        ) : (
          <div className="row">
            <div className="col-md-6">
              <h2 className="payment-center pb-2">Payment Info</h2>
              <div className="p-4 border">
                <h4 className="border-bottom">Bank Transfer</h4>
                <div className="ms-4 pt-1">
                  <div className="pb-2">
                    Jyoti Bikash Bank [Kamalpokhari Branch]
                  </div>
                  <div className="pb-2">
                    Account Name:{" "}
                    <span className="fw-medium"> The Nutritioncure Nepal </span>
                  </div>
                  <div>
                    Account No:
                    <span className="fw-medium"> 00100100478753000001 </span>
                  </div>
                </div>
              </div>
              <div className="p-4 border mt-3">
                <h4 className="border-bottom">Esewa Transfer</h4>
                <div className="ms-4 pt-1">
                  <div>
                    Phone No:
                    <span className="fw-medium"> 9860848881 </span>
                  </div>
                  <div>(Registered under Dietician Anushree)</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-md-4">
              <form
                onSubmit={handleSubmit}
                className="mb-4 mt-5 p-4 border rounded shadow"
              >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label fw-medium">
                    Name*
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={form.name}
                    onChange={handleChange}
                    className={`form-control ${
                      formErrors.name ? "is-invalid" : ""
                    }`}
                  />
                  {formErrors.name && (
                    <div className="invalid-feedback">{formErrors.name}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-medium">
                    Email*
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={form.email}
                    onChange={handleChange}
                    className={`form-control ${
                      formErrors.email ? "is-invalid" : ""
                    }`}
                  />
                  {formErrors.email && (
                    <div className="invalid-feedback">{formErrors.email}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="phone" className="form-label fw-medium">
                    Phone*
                  </label>
                  <PhoneNumberInput
                    phone={form.phone}
                    setPhone={(phone) =>
                      setForm((prevForm) => ({ ...prevForm, phone }))
                    }
                  />

                  {formErrors.phone && (
                    <div className="invalid-feedback">{formErrors.phone}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="image" className="form-label fw-medium">
                    Payment Screenshot{" "}
                    <span className="fw-normal small">
                      (Choose only if you have made payment)
                    </span>
                  </label>

                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    name="image"
                    id="image"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="primary-button px-5 mt-3">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentForm;
