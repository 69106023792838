import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <section>
      <div className="primary-color text-white text-center py-2">
        <span>Appointments Available Now!</span>{" "}
        <Link
          to="/book-appointment"
          className="text-white border-bottom border-white"
        >
          Book Appointment!
        </Link>
      </div>
    </section>
  );
};

export default Header;
