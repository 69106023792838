import React from "react";
import { FiCheckCircle } from "react-icons/fi";

const WhyUs = () => {
  return (
    <div className="why-choose-us">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="display-5 fw-semibold mb-3">
                {" "}
                <span className="primary-text-color">Why Choose </span>{" "}
                Nutrition Cure?
              </h2>
              <p className="mb-3 mb-md-5">
                We offer exceptional quality and support for your wellness.
                Here’s why we stand out:
              </p>
              <div className="row justify-content-center">
                <div className="col-lg-6 text-start">
                  <div className="fw-semibold">
                    <FiCheckCircle className="me-2" />
                    Tailored meal plans to match your unique needs and goals
                  </div>

                  <div className=" fw-semibold">
                    <FiCheckCircle className="me-2" />
                    Access to registered dietitians and nutrition experts
                  </div>
                  <div className=" fw-semibold">
                    <FiCheckCircle className="me-2" />
                    Innovative tools for easy meal tracking and progress
                    monitoring
                  </div>
                  <div className=" fw-semibold">
                    <FiCheckCircle className="me-2" />
                    Comprehensive support for both physical and mental
                    well-being
                  </div>
                  <div className=" fw-semibold">
                    <FiCheckCircle className="me-2" />
                    Connect with a supportive network of health-minded
                    individuals
                  </div>

                  <div className=" fw-semibold">
                    <FiCheckCircle className="me-2" />
                    Diet plans that fit seamlessly into your busy life
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
