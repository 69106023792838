import axiosInstance from "./axiosInstance";

// Extract country code and phone number
const extractPhoneDetails = (phone) => {
  const [countryCode, phoneNumber] = phone.split("-");
  return { countryCode, phoneNumber };
};

const token = process.env.REACT_APP_FORM_SUBMISSION_BEARER_TOKEN;

const FormSubmissionHandler = async ({
  fullName,
  email,
  phone,
  message,
  pageDetails,
  imageFile, // Expecting an image file
}) => {
  try {
    // Extract country code and phone number
    const { countryCode, phoneNumber } = extractPhoneDetails(phone);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("FullName", fullName);
    formData.append("Email", email);
    formData.append("CountryCode", countryCode || "+1"); // Default to +1 if not provided
    formData.append("PhoneNumber", phoneNumber || ""); // Handle phone number correctly
    formData.append("Message", message);
    formData.append("PageDetails", pageDetails); // Set the correct page details
    formData.append("BookingDetails", "1"); // Set booking details or handle based on your logic
    formData.append("AddedOn", new Date().toISOString()); // Current timestamp

    
    // If there is an image file, append it to FormData
    if (imageFile) {
      formData.append("Image", imageFile);
    }

    // Determine the correct API endpoint based on pageDetails
    const endpoint =
      pageDetails === "contact" ? "/api/saveUser" : "/api/saveTemporaryData";

    // Sending the POST request with FormData
    const response = await axiosInstance.post(endpoint, formData, {
      headers: {
        Authorization: `Bearer ${token}`, // Include auth token if needed
        // No need to set Content-Type manually; axios will set it automatically for FormData
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error:", error.response?.data || error.message);
    // Handle errors appropriately in your application
  }
};

export default FormSubmissionHandler;
