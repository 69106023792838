import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Custom hook for fetching country code
const useCountryCode = () => {
  const [country, setCountry] = useState("");

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_COUNTRY_API_URL);
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }
        const data = await response.json();
        setCountry(data.country_code.toLowerCase());
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    };
    fetchCountry();
  }, []);
  return country;
};

// it  renders an input field for entering a phone number using the react-phone-input-2 library. It is specifically designed to accommodate phone number formats with country-specific dialing codes.
const PhoneNumberInput = ({ phone, setPhone }) => {
  const country = useCountryCode();
  return (
    <>
      <div>
        <PhoneInput
          country={country}
          placeholder="Your Phone Number"
          preferredCountries={["np"]}
          enableSearch
          inputProps={{
            id: "floatingInputPhone",
            // required: true,
            autoFocus: true,
          }}
          inputStyle={{
            width: "100%",
            height: "calc(3rem + 2px)",
            border: "1px solid #ced4da",
            borderRadius: "0.25rem",
          }}
          containerStyle={{
            width: "100%", // Ensure the container takes the full width
            zIndex: 10,
          }}
          value={phone}
          onChange={(phone) => setPhone(phone)}
        />
        {/* Add a validation message for phone input here if necessary */}
      </div>
    </>
  );
};

export default PhoneNumberInput;
