import axiosInstance from "./axiosInstance";

const token = process.env.REACT_APP_FORM_SUBMISSION_BEARER_TOKEN;

const compareCalendlyWithTemporaryTable = async () => {
  try {
    // Make an API request to compare Calendly data with the temporary table
    const response = await axiosInstance.post(
      "/api/compareCalendlyWithTemporaryTable",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Assuming the API returns a JSON response
  } catch (error) {
    console.error("Error comparing Calendly data with temporary table:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export default compareCalendlyWithTemporaryTable;
