import React from "react";

const ErrorPage = () => {
  return (
    <div className="vh-100">
      <h2 className="d-flex justify-content-center mt-5 text-danger">
        404 not found!
      </h2>
    </div>
  );
};

export default ErrorPage;
