import React from "react";
import { Carousel } from "react-bootstrap";
import { RiDoubleQuotesL } from "react-icons/ri";

const testimonials = [
  {
    text: "Being a high school and national swimmer requires me to be in the best shape all year so I can swim to the best of my potential. Without a proper diet, that wouldn't be possible. Nutritionists at Nutrition Cure Nepal gave me a diet tailored to my needs as an athlete. With their help, I have been able to stay in shape while still enjoying the great selection of nutritional food they have selected for me. I would like to thank Anushree Acharya and her team at Nutrition Cure Nepal for  me become the best version of myself.",
    author: "Nirbhaya Thapa",
    location: "",
    image: "/img/testimonials/img1.png",
  },
  {
    text: "Happiness is the highest form of health. During Miss Nepal’s journey, we had a lot of rush in preparation, and sometimes we weren't able to take care of our health and diet. I am glad that Anushree and her team made the effort for us to maintain our diet accordingly. With a healthy diet, you become positive, which helps in leading a healthier life. Junk food, soda, and unhealthy diets may satisfy temporary taste buds but have a severe impact on our health. I was happy with the meal courses as the worked hard for each individual according to their daily meals. I still follow the diet, and it is working great for me. Thank you to the Nutrition Cure Nepal team.",
    author: "Nisha Pathak",
    location: "Miss Nepal Grand 2019",
    image: "/img/testimonials/img2.png",
  },
  {
    text: "Diet control instinct comes unpredictably, and with great effort. I have always been a big-time foodie, and eating junk had been my habit. Though I had to study a lot about nutrients, it was just for the sake of exams and didn't apply to my daily life. I remember how we were given our diet charts by Anushree and her team, with effort according to our needs. The Miss Nepal journey went quite well thanks to the chart. Even though we were constantly aware of proper food and nutrition, we were just a step away from breaking the habits we set for ourselves. Focusing on healthy eating is super important. When work stresses us, food comes to the rescue. So choose your food wisely, and choose your nutritionist wisely!",
    author: "Jenny Maharjan",
    location: "Miss Nepal 2019 Miss Best Natural Skin",
    image: "/img/testimonials/img3.png",
  },
  {
    text: "'Health is wealth' is one cliché phrase we probably hear all the time, but it's indeed true. And for a healthy life, a healthy diet is a must. I was aware of caloric intake but got to know much more when Anushree and the team enlightened us about the diet regarding our needs. Implementing the diet chart during the Miss Nepal 2019 journey was incredibly beneficial. Being on a diet doesn’t mean starving ourselves, but ensuring that we eat according to our body’s needs. Consultation with a dietitian is a great start for a healthy lifestyle. I would like to thank the Nutrition Cure Nepal team for taking care of our diet during the journey and for the efforts they have been putting in to inform others about the importance of having a healthy lifestyle through a proper diet.",
    author: "Sara Bajimaya",
    location: "Miss Pokhara 2018",
    image: "/img/testimonials/img4.png",
  },
  {
    text: "For us, Nutrition Cure Nepal was there for our rescue. Anushree, our dietitian from Nutrition Cure, asked us detailed questions about our food habits and came back to us with a custom chart for what we wanted to achieve. When you really look at a broader picture of what you eat and when you eat, it becomes very easy to identify problem and then solve it. Having a detailed chart custom-made for each of us made it so much easier to follow. We spend much on one meal at a restaurant. If we could use the cost of one of those meals to instead get our nutrition and diet in check, maybe we would all lead a healthier and happier life.",
    author: "Nancy Bogati",
    location: "Miss Pokhara 2019 Top 14",
    image: "/img/testimonials/img5.png",
  },
  {
    text: "A healthy and happy body is worth the effort. After winning the title of Miss Nepal, my journey of Miss Nepal started with an open heart, and of course, a diet was inevitable as getting in shape was necessary, although weight wasn't an issue for me. In a time like a rollercoaster ride, with a tight schedule of training sessions, maintaining a healthy diet plan was a challenging segment. I am grateful to Anushree and the Nutrition Cure Nepal team for the effort to maintain my everyday diet during the session. My gratitude and appreciation go to the team who genuinely worked for our health and made us realize the importance of a healthy diet and nutrition. Thank you, Nutrition Cure Nepal team!",
    author: "Alisha Joshi",
    location: "Miss Newa Pokhara, Miss Nepal 2019 Finalist",
    image: "/img/testimonials/img6.png",
  },
  {
    text: "I had to be conscious about my health when my blood pressure started to rise. I was working out, hitting the gym, but not losing weight—one of the major factors contributing to high blood pressure. So I had to take control of my diet. I never knew it was so calculative and precise until I followed the diet chart provided to me by Anushree from Nutrition Cure. I am now aware of my caloric needs, balancing out the carbs, protein, and fat that I consume, as well as the micronutrients my body needs. I am also aware of the water intake my body requires.",
    author: "David Shakya",
    location: "International Education Consultant",
    image: "/img/testimonials/img7.png",
  },
  {
    text: "Thank you to the Nutrition Cure team for bringing positive changes in me because of whom my focus has been on healthy eating rather than dieting! For the person who was dieting for so long but without any results, I’m very glad that I found you. I am so happy with the changes and suggestions you provide me in my daily meal plans, both at home or while dining out. Your care and encouragement have completely changed my relationship with food.",
    author: "Sita Neupane",
    location: "",
    image: "/img/testimonials/img8.png",
  },
  {
    text: "By following the charts provided and maintaining them, I was able to reduce about 7 kgs in 1.5 months. This might not be a major weight loss, but it was exactly how I had planned. Also, getting in touch with the Nutrition Cure team has been easy. I approached them during the lockdown period, and most of the communications were done online. I visited their center once to get a Body Analysis Report, and that’s all it took. I have changed my diet chart multiple times after tracking my progress. Not to mention, I am out of the country now, and I could still reach out to them over a simple email and their social media. This has been an eye-opener and a lesson not just to me but to my family as well. My family and I are happy with what I have achieved. I have gained a little weight during travel and staying abroad, but it has been constant after I have settled down. Thank you, Anushree, and the Nutrition Cure team, for the actual nutrition care of my body!",
    author: "David Shakya",
    location: "International Education Consultant",
    image: "/img/testimonials/img9.png",
  },
];

const TestimonialCarousel = () => {
  return (
    <section className="py-5 bg-light">
      <div className="container">
        <h2 className="fw-bold text-center">
          Testi<span className="primary-text-color">monials</span>
        </h2>
        <p className="text-center mb-4 mb-md-5 text-secondary">
          What our customers say about us?
        </p>

        <Carousel
          className="testimonial-carousel container"
          controls={true}
          indicators={false}
          interval={10000}
          data-bs-theme="dark"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <div className="row justify-content-center">
                <div className="col-11 col-md-9">
                  <div className="testimonial-card d-flex flex-column justify-content-center align-items-center bg-white rounded shadow-sm   p-4 p-md-5">
                    <blockquote className="blockquote text-center">
                      <p className="fs-6 fst-italic ">"{testimonial.text}"</p>
                      <RiDoubleQuotesL className="mb-3 primary-text-color fs-2" />
                      <div>
                        <img
                          src={testimonial.image}
                          alt={`Image of ${testimonial.author}`}
                          className="img-fluid rounded-circle mb-3"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            border: "2px solid #f0f0f0",
                          }}
                        />
                      </div>
                      <div className="fs-5 fw-semibold">
                        {testimonial.author}
                      </div>
                      <div className="fs-6">{testimonial.location}</div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
